import { FC, useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Box, Breadcrumbs, Button, ButtonProps, Divider, FormControl, Grid, IconButton, TextField, Typography, Link, Card, CardContent, Stack } from "@mui/material";
import { IRandomInspection, IRandomInspectionDetail } from "../Services/RandomInspectionService";
import DeleteIcon from '@mui/icons-material/Delete';
import styled from "@emotion/styled";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ImagePreview from "../../../components/ImagePreview";
import { IUploadFile } from "../../Company/Services/CompanyService";
import { IOrder } from "../Services/OrderService";

import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DialogConfirm from "../../../components/DialogConfirm";
import { IUserLogined } from "../../Login/Services/LoginService";
import { IFarmProductReceipt, IFarmReceiptBasket } from "../Services/FarmProductReceipt";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";
import { useFileManagement } from "../../../utils/fileHandlers";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { IGrade } from "../Services/GradeService";
import { v4 as uuidv4 } from "uuid";
import SimpleBackdrop from "../../../components/SimpleBackdrop";
import { IInvoice } from "../Services/InvoiceService";



interface RandomInspectionCreateViewProps {
  onCreate: (data: IRandomInspection, uploadFile: IUploadFile) => void;
  onUpdate: (id: string, data: IRandomInspection, uploadFile: IUploadFile[], deleteFile: IUploadFile[]) => void;
  randomInspection: IRandomInspection;
  onDelete: (data: IRandomInspection) => void;
  order: IOrder[];
  permission: IUserDetail;
  randomInspectionId: string;
  isLoading: boolean;
  user: IUserLogined;
  onGetOrders: (invoiceNo?: string) => void;
  // orders: IOrder[];
  invoices: IInvoice[];
}

const defaultBasketDetails = {
  grade: { id: '', name: '' } as IGrade,
  emptyBasketWeight: 0, totalBasketWeight: 0, details: [] as IFarmReceiptBasket[],
  basketAmount: 0, totalWeight: 0, netWeight: 0, pricePerKg: 0, paidPrice: 0,
  out: 0, claimsWeight: 0, totalInspectionWeight: 0, cutPoleWeight: 0, preWeighingWeight: 0
};

const RandomInspectionCreateView: FC<RandomInspectionCreateViewProps> = (props) => {
  const userGroup = props.user.userGroup;
  const { handleSubmit, reset, getValues, setValue, control, formState: { errors }, watch } = useForm<IRandomInspection>();

  const [actualWeight, setActualWeight] = useState(0);
  const [totalInspectionWeight, setTotalInspectionWeight] = useState(0);
  const [totalEliminate, setTotalEliminate] = useState(0);
  const [claimsWeight, setClaimsWeight] = useState(0);
  const [cutPoleWeight, setCutPoleWeight] = useState(0);
  const [outWeight, setOutWeight] = useState(0);
  const [farmReceipt, setFarmReceipt] = useState<IFarmProductReceipt[]>();
  const [selectedFarmReceipt, setSelectedFarmReceipt] = useState<IFarmProductReceipt | null>(null);

  const [openImage, setOpenImage] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { from } = location.state || { from: null };
  const isFromStatus = from === "status" || from === "order";
  const isDisable = isFromStatus ? true : false;
  const filteredFarmReceipts = farmReceipt?.filter(receipt => receipt.status === 'approved') || [];
  const order = watch('order');
  const {
    selectedFiles,
    images,
    pdfFiles,
    deleteImages,
    setImages,
    setPdfFiles,
    setSelectedFiles,
    setDeleteImages,
    handleFileChange,
    handleFileRemove,
    handleFileRemoveImageList,
    handleFileRemovePdfList,
  } = useFileManagement();

  const [basketDetailsList, setBasketDetailsList] = useState([{ ...defaultBasketDetails, id: uuidv4() }]);


  useEffect(() => {
    if (props.randomInspection && props.randomInspectionId) {
      reset(props.randomInspection);
      setImages(props.randomInspection.image || []);
      setPdfFiles(props.randomInspection.file || []);
      handleDataFromRandomDetail(props.randomInspection.randomInspectionDetails || []);
      const selectedOrder = props.order.find(order => order.id === props.randomInspection.order?.id);
      if (selectedOrder) {
        setFarmReceipt(selectedOrder.farmProductReceipts || []);
        const selectedReceipt = selectedOrder.farmProductReceipts?.find(receipt => receipt.id === props.randomInspection.farm?.id) || null;
        setSelectedFarmReceipt(selectedReceipt);
      } else {
        setFarmReceipt([]);
        setSelectedFarmReceipt(null);
      }
      if (props.randomInspection.invoiceNo?.id) {
        props.onGetOrders(props.randomInspection.invoiceNo?.id);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.randomInspection, props.randomInspectionId]);

  useEffect(() => {
    const selectedOrder = props.order.find(order => order.id === props.randomInspection.order?.id);

    if (selectedOrder) {
      setFarmReceipt(selectedOrder.farmProductReceipts || []);
      const selectedReceipt = selectedOrder.farmProductReceipts?.find(receipt => receipt.id === props.randomInspection.farm?.id) || null;
      setSelectedFarmReceipt(selectedReceipt);
    } else {
      setFarmReceipt([]);
      setSelectedFarmReceipt(null);
    }
  }, [props.order, props.randomInspection]);



  useEffect(() => {
    const totalEliminate = Number(claimsWeight) + Number(totalInspectionWeight) + Number(cutPoleWeight) + Number(outWeight);
    setTotalEliminate(totalEliminate);
    const preWeighingWeight = getValues("preWeighingWeight") || 0;

    const remainingWeight = Number(actualWeight) - totalEliminate - Number(preWeighingWeight);
    setValue("totalRemainingWeight", remainingWeight);

    calLostM(basketDetailsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualWeight, claimsWeight, totalInspectionWeight, selectedFarmReceipt?.pricePerKg, setValue, cutPoleWeight, outWeight]);


  useEffect(() => {
    if (props.randomInspectionId) {
      const { actualWeight, claimsWeight, totalInspectionWeight, cutPoleWeight, out } = props.randomInspection;
      setActualWeight(actualWeight || 0);
      setClaimsWeight(claimsWeight || 0);
      setCutPoleWeight(cutPoleWeight || 0);
      setOutWeight(out || 0);
      setTotalInspectionWeight(totalInspectionWeight || 0);
    }

  }, [props.randomInspectionId, props.randomInspection]);


  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  const onSubmit = (type: "create" | "delete" | "approve" | "reject") => {
    if (type === "delete") {
      setOpenConfirm({
        open: true,
        title: "ลบข้อมูล",
        message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
        color: "error",
        type: "delete",
      });
    } else {
      handleSubmit(() => {
        setOpenConfirm({
          open: true,
          title: isFromStatus ? type === 'reject' ? "ปฏิเสธรายการ" : "อนุมัติรายการ" : "บันทึกข้อมูล",
          message: isFromStatus ? type === 'reject' ? "คุณต้องการปฏิเสธรายการสุ่มผ่านี้ใช่หรือไม่" : "คุณต้องการอนุมัติรายการสุ่มผ่านี้ใช่หรือไม่" : "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
          color: type === "reject" ? "error" : "primary",
          type: "create",
        });
      })();
    }
  };

  const onFormSubmit = (data: IRandomInspection) => {
    const processedData = {
      ...data,
      status: isFromStatus ? isReject ? "rejected" : "approved" : "waiting",
      claimsWeight: getValues("claimsWeight") || 0,
      totalInspectionWeight: getValues("totalInspectionWeight") || 0,
      cutPoleWeight: getValues("cutPoleWeight") || 0,
      out: getValues("out") || 0,
      docDate: getValues("docDate") || dayjs().format("YYYY-MM-DD"),
    };

    if (basketDetailsList && basketDetailsList.length > 0) {
      let _randomInspectionDetails = [] as IRandomInspectionDetail[];
      basketDetailsList.forEach((item) => {
        _randomInspectionDetails.push({
          grade: item.grade,
          out: item.out,
          claimsWeight: item.claimsWeight,
          totalInspectionWeight: item.totalInspectionWeight,
          cutPoleWeight: item.cutPoleWeight,
          paidPrice: item.paidPrice,
          pricePerKg: item.pricePerKg,
          weight: item.netWeight,
        });
      });
      processedData.randomInspectionDetails = _randomInspectionDetails;
    }
    if (props.randomInspectionId) {
      props.onUpdate(props.randomInspectionId, processedData, selectedFiles, deleteImages);
    } else {
      props.onCreate(processedData, selectedFiles);
    }
    setIsReject(false);
    setSelectedFiles([]);
    setDeleteImages([]);
  };



  const onDelete = () => {
    const data = getValues();
    props.onDelete({ ...data, isDelete: true });
  };

  const onReject = () => {
    setIsReject(true);
    onSubmit("reject");
  };

  const onApprove = () => {
    setIsReject(false);
    onSubmit("approve");
  };


  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const onImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setOpenImage(true);
  };

  const handleDataFromRandomDetail = (data: IRandomInspectionDetail[]) => {
    if (data && data.length > 0) {
      const _datas = [] as any[];
      data.forEach((item, mainIndex) => {
        const index = _datas.findIndex((i) => i.grade?.id === item.grade?.id);
        if (index > -1) {
          _datas[index].netWeight = Number(_datas[index].weight);
          _datas[index].out = Number(_datas[index].out) + Number(item.out);
          _datas[index].claimsWeight = Number(_datas[index].claimsWeight) + Number(item.claimsWeight);
          _datas[index].totalInspectionWeight = Number(_datas[index].totalInspectionWeight) + Number(item.totalInspectionWeight);
          _datas[index].cutPoleWeight = Number(_datas[index].cutPoleWeight) + Number(item.cutPoleWeight);
        } else {
          _datas.push(item);
          _datas[mainIndex].netWeight = Number(item.weight);
        }
      });
      setBasketDetailsList(_datas);
    }
  };

  const handleGradeFromFarm = (data: IFarmProductReceipt) => {
    if (data.farmReceiptBasket && data.farmReceiptBasket.length > 0) {
      const sortByGrade = data.farmReceiptBasket.sort((a, b) => (a.grade?.name ?? "").localeCompare(b.grade?.name ?? ""));
      const checkGroupGrade = sortByGrade.reduce<IFarmReceiptBasket[]>((acc, cur) => {
        const index = acc.findIndex((item) => item.grade?.id === cur.grade?.id);
        if (index > -1) {
          if (acc[index]) {
            acc[index].weight = (acc[index].weight ?? 0);
          }
        } else {
          acc.push(cur);
        }
        return acc;
      }, []);
      if (checkGroupGrade && checkGroupGrade.length > 0) {
        let datas = [] as any[];
        checkGroupGrade.forEach((item) => {
          const filterDetailsByGrade = sortByGrade.filter((detail) => detail.grade?.id === item.grade?.id);
          const details = filterDetailsByGrade;
          // const basketAmount = filterDetailsByGrade.length;
          const basketAmount = filterDetailsByGrade.filter((detail) => detail.weight && detail.weightPerBasket && detail.weight > detail.weightPerBasket).length;
          const totalWeight = filterDetailsByGrade.reduce((acc, cur) => Number(acc ?? 0) + Number(cur.weight ?? 0), 0);
          const emptyBasketWeight = filterDetailsByGrade[0].emptyBasketWeight ?? 3;
          const pricePerKg = filterDetailsByGrade[0].pricePerKg ?? 0;
          const paidPrice = filterDetailsByGrade[0].paidPrice ?? 0;
          const totalBasketWeight = Number(emptyBasketWeight) * Number(basketAmount);
          const netWeight = totalWeight - totalBasketWeight;

          datas.push({
            grade: item.grade, emptyBasketWeight: emptyBasketWeight, details: details, basketAmount: basketAmount, totalWeight: totalWeight,
            totalBasketWeight: totalBasketWeight, netWeight: netWeight, pricePerKg: pricePerKg, paidPrice: paidPrice,
            out: 0, claimsWeight: 0, totalInspectionWeight: 0, cutPoleWeight: 0
          });
        });
        setBasketDetailsList(datas);
      }
    }
  };

  const calTotalOutWeight = (updatedBasketDetailsList: any) => {
    const totalOut = updatedBasketDetailsList.reduce((acc: any, cur: any) => Number(acc) + Number(cur.out || 0), 0);
    setValue("out", totalOut);
    setOutWeight(totalOut);
  };

  const calTotalClaimsWeight = (updatedBasketDetailsList: any) => {
    const totalClaimsWeight = updatedBasketDetailsList.reduce((acc: any, cur: any) => Number(acc) + Number(cur.claimsWeight || 0), 0);
    setValue("claimsWeight", totalClaimsWeight);
    setClaimsWeight(totalClaimsWeight);
  };

  const calTotalInspectionWeight = (updatedBasketDetailsList: any) => {
    const totalInspectionWeight = updatedBasketDetailsList.reduce((acc: any, cur: any) => Number(acc) + Number(cur.totalInspectionWeight || 0), 0);
    setValue("totalInspectionWeight", totalInspectionWeight);
    setTotalInspectionWeight(totalInspectionWeight);
  };

  const calTotalCutPoleWeight = (updatedBasketDetailsList: any) => {
    const totalCutPoleWeight = updatedBasketDetailsList.reduce((acc: any, cur: any) => Number(acc) + Number(cur.cutPoleWeight || 0), 0);
    setValue("cutPoleWeight", totalCutPoleWeight);
    setCutPoleWeight(totalCutPoleWeight);
  };

  const calLostM = (updatedBasketDetailsList: any) => {
    const totalLostM = updatedBasketDetailsList.reduce((acc: any, cur: any) => Number(acc) + ((Number(cur.cutPoleWeight || 0) + Number(cur.out || 0) + Number(cur.claimsWeight || 0) + Number(cur.totalInspectionWeight || 0)) * Number(cur.pricePerKg || 0)), 0);
    setValue("lostM", totalLostM);
  };



  return (
    <Box component={'div'}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="back"
            onClick={() => navigate("/process-random-inspection")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          {/* NOTE: check from order or not */}
          {from === "order" ? <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ตรวจสอบคุณภาพ</Typography>
            <Link component="button" onClick={() => navigate(`/process-order-edit/${order?.id}`)}>
              ใบคำสั่งซื้อ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.randomInspectionId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs> : <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ตรวจสอบคุณภาพ</Typography>
            <Link component="button" onClick={() => navigate("/process-random-inspection")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.randomInspectionId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>}
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.randomInspectionId && props.permission.delete) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => onSubmit("delete")}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              {props.randomInspectionId && <Grid item xs={12} sm={12} md={12} lg={12} mb={3}>
                <Typography variant="h6">{props.randomInspection?.docNo}</Typography>
              </Grid>}
              <Box component="form" onSubmit={handleSubmit(onFormSubmit)} mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                        <Controller
                          name="docDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              disabled={isDisable}
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  error: !!errors.docDate,
                                  helperText: errors.docDate ? "กรุณาระบุวันที่" : "",
                                }
                              }}
                              label="วันที่"
                              format="DD/MM/YYYY"
                              {...field}
                              defaultValue={dayjs(new Date())}
                              value={field.value ? dayjs(field.value) : dayjs(new Date())}
                              onChange={(date) => {
                                field.onChange(date ? date.format('YYYY-MM-DD') : null);
                              }}

                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Controller
                      name="invoiceNo"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          value={field.value as IInvoice || null}
                          options={props.invoices ?? []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.invoiceNo || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setValue('order', undefined);
                              field.onChange(newValue);
                              props.onGetOrders(newValue.id);
                              setValue('farm', undefined);

                            } else {
                              field.onChange(null);
                              setValue('order', undefined);
                              setValue('farm', undefined);

                            }
                          }}
                          disabled={isDisable}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Invoice No."
                              error={!!errors.invoiceNo}
                              helperText={errors.invoiceNo ? "กรุณาเลือก Invoice" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <FormControl fullWidth error={!!errors.order}>
                      <Controller
                        name="order"
                        control={control}
                        rules={{ required: 'กรุณาเลือกโปรเจกต์' }}
                        render={({ field }) => (
                          <>
                            <Autocomplete
                              id="order-autocomplete"
                              options={props.order.filter((option) => option.isComplete === false) || []}
                              getOptionLabel={(option) => option.projectName || ""}
                              value={field.value || null}
                              onChange={(event, newValue) => {
                                field.onChange(newValue);
                                if (newValue?.farmProductReceipts) {
                                  setFarmReceipt(newValue?.farmProductReceipts || undefined);
                                  setSelectedFarmReceipt(null);
                                }
                              }}
                              disabled={isDisable}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="ชื่อโปรเจกต์"
                                  error={!!errors.order}
                                  helperText={errors.order ? errors.order.message : ''}
                                />
                              )}
                            />
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <FormControl fullWidth error={!!errors.farm}>
                      <Controller
                        name="farm"
                        control={control}
                        rules={{ required: 'กรุณาเลือกเลขที่รับจากสวน' }}
                        render={({ field }) => (
                          <>
                            <Autocomplete
                              id="receive-autocomplete"
                              options={filteredFarmReceipts}
                              getOptionLabel={(option) => option.docNo || ""}
                              value={field.value || null}
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  field.onChange(newValue);
                                  const actualWeight = Number(newValue?.netWeightFarm || 0) + Number(newValue?.preWeighingWeight || 0);
                                  setValue("actualWeight", actualWeight);
                                  setActualWeight(actualWeight);
                                  setValue("preWeighingWeight", newValue?.preWeighingWeight || 0);
                                  setSelectedFarmReceipt(newValue || null);
                                  handleGradeFromFarm(newValue);
                                } else {
                                  setBasketDetailsList([{ ...defaultBasketDetails, id: uuidv4() }]);
                                }
                              }}
                              disabled={isDisable}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="เลขที่รับจากสวน"
                                  error={!!errors.farm}
                                  helperText={errors.farm ? errors.farm.message : ''}
                                />
                              )}
                            />

                          </>
                        )}
                      />
                    </FormControl>

                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Box
                      sx={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px'
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>รับโดย:</span> {selectedFarmReceipt?.createBy?.firstName ?? "N/A"} {selectedFarmReceipt?.createBy?.lastName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>โรงอบ:</span> {selectedFarmReceipt?.vapourHeatTreatment?.name ?? "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>น้ำหนักทั้งหมด (รวมตะกร้า):</span> {thaiFormatNumber(selectedFarmReceipt?.totalWeight?.toString() || '0') ?? 0} กก.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          {(() => {
                            const _actualWeight = Number(selectedFarmReceipt?.netWeightFarm || 0) + Number(selectedFarmReceipt?.preWeighingWeight || 0);
                            return (
                              <Typography variant="body1">
                                <span style={{ fontWeight: 'bold' }}>น้ำหนักทั้งหมด (หลังหักตะกร้า):</span> {thaiFormatNumber(_actualWeight.toString() || '0')} กก.
                              </Typography>
                            );
                          })()}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  {
                    basketDetailsList && basketDetailsList.length && basketDetailsList[0].grade.name && (
                      <Grid item xs={12} mt={2} mb={2}>
                        <Box
                          sx={{
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px'
                          }}>
                          <Grid container spacing={2}>
                            {
                              basketDetailsList.map((basket, index) => (
                                <>
                                  {
                                    index !== 0 && (
                                      <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Divider sx={{ width: '90%', margin: '0 auto' }} />
                                      </Grid>
                                    )
                                  }
                                  <Grid item xs={12} key={index} mb={2}>
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={6} md={3}>
                                        <Typography variant="body1">
                                          <span style={{ fontWeight: 'bold' }}>เกรด:</span> {basket.grade?.name}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={3}>
                                        <Typography variant="body1">
                                          <span style={{ fontWeight: 'bold' }}>น้ำหนักทั้งหมด:</span>
                                          {basket.netWeight ? thaiFormatNumber(basket.netWeight.toString()) : '-'}
                                          กก
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={3}>
                                        <Typography variant="body1">
                                          <span style={{ fontWeight: 'bold' }}>ราคา/Kg:</span> {basket.pricePerKg ? thaiFormatNumber(basket.pricePerKg.toString()) : '-'} บาท
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={3}>
                                        <Typography variant="body1">
                                          <span style={{ fontWeight: 'bold' }}>ราคา:</span> {basket.paidPrice ? thaiFormatNumber(basket.paidPrice.toString()) : '-'} บาท
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <FormControl fullWidth>
                                          <TextField
                                            key={index}
                                            disabled={isDisable}
                                            fullWidth
                                            type="number"
                                            label="น้ำหนักคัดออก (Kg)"
                                            value={basket.out}
                                            onFocus={(e) => {
                                              if (Number(e.target.value) === 0) {
                                                e.target.select();
                                              }
                                            }}
                                            onBlur={(e) => {
                                              if (e.target.value === '') {
                                                e.target.value = '0';
                                              }
                                            }}
                                            onChange={(e) => {
                                              const value = parseFloat(e.target.value) || 0;
                                              setBasketDetailsList((prevBasketDetailsList) => {
                                                const newBasketDetails = prevBasketDetailsList.map((item, i) => {
                                                  if (index === i) {
                                                    return { ...item, out: value };
                                                  }
                                                  return item;
                                                });
                                                calTotalOutWeight(newBasketDetails);
                                                return newBasketDetails;
                                              });
                                            }}
                                            InputLabelProps={{ shrink: basket.out !== null && basket.out !== undefined }}
                                            InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={isDisable}
                                            fullWidth
                                            type="number"
                                            label="น้ำหนักคืนสวน (Kg)"
                                            value={basket.claimsWeight}
                                            onFocus={(e) => {
                                              if (Number(e.target.value) === 0) {
                                                e.target.select();
                                              }
                                            }}
                                            onBlur={(e) => {
                                              if (e.target.value === '') {
                                                e.target.value = '0';
                                              }
                                            }}
                                            onChange={(e) => {
                                              const value = parseFloat(e.target.value) || 0;
                                              setBasketDetailsList((prevBasketDetailsList) => {
                                                const newBasketDetails = prevBasketDetailsList.map((item, i) => {
                                                  if (index === i) {
                                                    return { ...item, claimsWeight: value };
                                                  }
                                                  return item;
                                                });
                                                calTotalClaimsWeight(newBasketDetails);
                                                return newBasketDetails;
                                              });
                                            }}
                                            InputLabelProps={{ shrink: basket.claimsWeight !== null && basket.claimsWeight !== undefined }}
                                            InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={isDisable}
                                            fullWidth
                                            type="number"
                                            label="น้ำหนักสุ่มผ่าหลังชั่ง (Kg)"
                                            value={basket.totalInspectionWeight}
                                            onFocus={(e) => {
                                              if (Number(e.target.value) === 0) {
                                                e.target.select();
                                              }
                                            }}
                                            onBlur={(e) => {
                                              if (e.target.value === '') {
                                                e.target.value = '0';
                                              }
                                            }}
                                            onChange={(e) => {
                                              const value = parseFloat(e.target.value) || 0;
                                              setBasketDetailsList((prevBasketDetailsList) => {
                                                const newBasketDetails = prevBasketDetailsList.map((item, i) => {
                                                  if (index === i) {
                                                    return { ...item, totalInspectionWeight: value };
                                                  }
                                                  return item;
                                                });
                                                calTotalInspectionWeight(newBasketDetails);
                                                return newBasketDetails;
                                              });
                                            }}
                                            InputLabelProps={{ shrink: basket.totalInspectionWeight !== null && basket.totalInspectionWeight !== undefined }}
                                            InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={isDisable}
                                            fullWidth
                                            type="number"
                                            label="น้ำหนักขั้วที่ตัดทิ้ง (Kg)"
                                            value={basket.cutPoleWeight}
                                            onFocus={(e) => {
                                              if (Number(e.target.value) === 0) {
                                                e.target.select();
                                              }
                                            }}
                                            onBlur={(e) => {
                                              if (e.target.value === '') {
                                                e.target.value = '0';
                                              }
                                            }}
                                            onChange={(e) => {
                                              const value = parseFloat(e.target.value) || 0;
                                              setBasketDetailsList((prevBasketDetailsList) => {
                                                const newBasketDetails = prevBasketDetailsList.map((item, i) => {
                                                  if (index === i) {
                                                    return { ...item, cutPoleWeight: value };
                                                  }
                                                  return item;
                                                });
                                                calTotalCutPoleWeight(newBasketDetails);
                                                return newBasketDetails;
                                              });
                                            }}
                                            InputLabelProps={{ shrink: basket.cutPoleWeight !== null && basket.cutPoleWeight !== undefined }}
                                            InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </>
                              ))
                            }
                          </Grid>
                        </Box>
                      </Grid>
                    )
                  }
                  {/* <Grid item xs={12} sm={12} md={3} lg={3}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={isDisable}
                        fullWidth
                        type="number"
                        label="น้ำหนักคัดออก (Kg)"
                        {...register("out", {
                          onChange: (e) => setOutWeight(parseFloat(e.target.value) || 0)
                        })}
                        InputLabelProps={{ shrink: getValues("out") ? true : undefined }}
                        InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={isDisable}
                        fullWidth
                        type="number"
                        label="น้ำหนักคืนสวน (Kg)"
                        {...register("claimsWeight", {
                          onChange: (e) => setClaimsWeight(parseFloat(e.target.value) || 0)
                        })}
                        InputLabelProps={{ shrink: getValues("claimsWeight") ? true : undefined }}
                        InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={isDisable}
                        fullWidth
                        type="number"
                        label="น้ำหนักสุ่มผ่าหลังชั่ง (Kg)"
                        {...register("totalInspectionWeight", {
                          onChange: (e) => setTotalInspectionWeight(parseFloat(e.target.value) || 0)
                        })}
                        InputLabelProps={{ shrink: getValues("totalInspectionWeight") ? true : undefined }}

                        InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={isDisable}
                        fullWidth
                        type="number"
                        label="น้ำหนักขั้วที่ตัดทิ้ง (Kg)"
                        {...register("cutPoleWeight", {
                          onChange: (e) => setCutPoleWeight(parseFloat(e.target.value) || 0)
                        })}
                        InputLabelProps={{ shrink: getValues("cutPoleWeight") ? true : undefined }}

                        InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                      />
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={7} md={7} lg={7}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography>เอกสาร</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button component="label" variant="outlined" disabled={isDisable} startIcon={<UploadFileIcon />}>
                          เลือกไฟล์
                          <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png, .pdf" multiple onChange={handleFileChange} />
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png, .pdf</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {selectedFiles.length > 0 && (
                          <Box component={'div'} mt={1}>
                            {selectedFiles.map((file: any, index: number) => (
                              <Grid item xs={12} sm={12} md={12} key={`sf-${index.toString()}`} mt={1}>
                                <Grid container >
                                  <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton size="small" color="error" onClick={() => handleFileRemove(index)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11} sm={11} md={11} lg={11}>
                                    {file.type === 'application/pdf' ? (
                                      <Typography variant="body2">
                                        <a
                                          href={URL.createObjectURL(file)}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ color: "inherit", cursor: "pointer" }}
                                        >
                                          {file.name}
                                        </a>
                                      </Typography>
                                    ) : (
                                      <img
                                        src={URL.createObjectURL(file)}
                                        alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                        style={{ maxWidth: "50%", height: "auto", cursor: "pointer" }}
                                        onClick={() => onImageClick(URL.createObjectURL(file))}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {images && (
                          <Box component={'div'} mt={1}>
                            {images.map((file: any, index: number) => (
                              <Grid item xs={12} sm={12} md={12} key={`im-${index.toString()}`} mt={1}>
                                <Grid container >
                                  <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton size="small"
                                      color="error" onClick={() => handleFileRemoveImageList(file)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11} sm={11} md={11} lg={11}>
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}/uploads/${(file).name
                                        }`}
                                      alt="Selected"
                                      style={{ maxWidth: "50%", height: "auto", cursor: 'pointer' }}
                                      onClick={() => onImageClick(`${process.env.REACT_APP_API_URL}/uploads/${(file).name}`)}

                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        {pdfFiles.length > 0 && (
                          <Box component={'div'} mt={1}>
                            {pdfFiles.map((file: any, index: number) => (
                              <Grid item xs={12} key={`pdf-${index}`} mt={1}>
                                <Grid container>
                                  <Grid item xs={1} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                    <IconButton size="small" color="error" onClick={() => handleFileRemovePdfList(file)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11}>
                                    <a
                                      href={`${process.env.REACT_APP_API_URL}/uploads/${file.name}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: "inherit", cursor: "pointer" }}
                                    >
                                      {file.originalName}
                                    </a>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={5} md={5} lg={5} mt={1} justifyContent={'flex-end'}>
                    <Grid container spacing={2}>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography ><b>น้ำหนักทั้งหมดหลังหักตะกร้า (Kg)</b></Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography >
                          <b> {getValues('actualWeight') ? thaiFormatNumber(getValues('actualWeight').toString()) : '0.00'}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography color={'error'}>น้ำหนักสุ่มผ่าก่อนชั่ง (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography color={'error'}>
                          {getValues('preWeighingWeight') ? thaiFormatNumber(getValues('preWeighingWeight').toString()) : '0.00'}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography color={'error'}>น้ำหนักที่คัดออก (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography color={'error'}>
                          {thaiFormatNumber(parseFloat(outWeight.toString()).toFixed(2))}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography color={'error'}>น้ำหนักคืนสวน (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography color={'error'}>
                          {thaiFormatNumber(parseFloat(claimsWeight.toString()).toFixed(2))}
                        </Typography>
                      </Grid>

                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography color={'error'}>น้ำหนักสุ่มผ่าหลังชั่ง (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography color={'error'}>
                          {thaiFormatNumber(parseFloat(totalInspectionWeight.toString()).toFixed(2))}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography color={'error'}>น้ำหนักขั้วที่ตัดทิ้ง (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography color={'error'}>
                          {thaiFormatNumber(parseFloat(cutPoleWeight.toString()).toFixed(2))}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography color={'error'}><b>รวมน้ำหนักคัดออก (Kg)</b></Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography color={'error'}>
                          <b> {thaiFormatNumber(parseFloat(totalEliminate.toString()).toFixed(2))}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography ><b>น้ำหนักสุทธิ (Kg)</b></Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Controller
                          name="totalRemainingWeight"
                          control={control}
                          defaultValue={0}
                          render={({ field }) => (
                            <Typography>
                              <b>{thaiFormatNumber(parseFloat(field.value?.toString() || '0').toFixed(2))}</b>
                            </Typography>
                          )}
                        />

                      </Grid>
                      {
                        userGroup?.canFinance && (
                          <>
                            <Grid item xs={8} sm={8} md={8} lg={8}>
                              <Typography >มูลค่าที่สูญเสีย (บาท)</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                              <Controller
                                name="lostM"
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                  <Typography>
                                    <b>{thaiFormatNumber(parseFloat(field.value?.toString() || '0').toFixed(2))}</b>
                                  </Typography>
                                )}
                              />
                            </Grid>
                          </>
                        )
                      }


                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Grid container spacing={2} >
                      <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} alignItems={"center"}>
                          <Typography>
                            <b>ผู้ทํารายการ: </b>
                            {props.randomInspection?.createBy?.firstName} {props.randomInspection?.createBy?.lastName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} alignItems={"center"}>
                          <Typography>
                            <b>ผู้อนุมัติ/ปฏิเสธ: </b>
                            {props.randomInspection?.approveBy?.firstName} {props.randomInspection?.approveBy?.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} justifyContent={"end"}>
                        <Stack spacing={2} direction="row">
                          {
                            isFromStatus ? (
                              <>
                                {
                                  userGroup?.canApprove && props.randomInspection?.status === "waiting" ?
                                    <>
                                      <Button
                                        variant="contained"
                                        fullWidth
                                        color="error"
                                        onClick={onReject}
                                      >
                                        ปฏิเสธ
                                      </Button>
                                      <Button
                                        variant="contained"
                                        color="success"
                                        fullWidth
                                        onClick={onApprove}
                                        style={{ marginLeft: '8px' }}
                                      >
                                        อนุมัติ
                                      </Button></> :
                                    null
                                }

                              </>
                            ) :
                              <>
                                {
                                  props.randomInspection?.status === "waiting" || from === "create" ? <>  <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => navigate("/process-random-inspection")}
                                  >
                                    ยกเลิก
                                  </Button>
                                    {props.permission.create || (props.permission.edit && props.randomInspection) ? (
                                      <Button
                                        id="submit"
                                        variant="contained"
                                        onClick={() => onSubmit("create")}
                                      >
                                        บันทึก
                                      </Button>
                                    ) : null}
                                  </> :
                                    null
                                }

                              </>
                          }
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            onDelete();
          } else {
            handleSubmit(onFormSubmit)();
          }
        }}
      />
      <ImagePreview
        open={openImage}
        imageSrc={selectedImage}
        onClose={() => setOpenImage(false)}
      />
      <SimpleBackdrop open={props.isLoading ?? false} />
    </Box >
  );
};

export default RandomInspectionCreateView;

