import React, { FC, useEffect, useState } from "react";
import { IUploadFile } from "../../Inventory/Services/ReceiveService";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { IDistrict, IMarket, IProvince, ISubDistrict } from "../Services/MarketService";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Box, Breadcrumbs, Grid, IconButton, Typography, Link, Button, ButtonProps, Divider, TextField, useMediaQuery, FormControl, InputLabel, Select, MenuItem, Autocomplete, Card, CardContent, Stack, FormHelperText, createFilterOptions } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { theme } from "../../../themes";
import { IUserLogined } from "../../Login/Services/LoginService";
import { IEliminate, IEliminateCreate, IEliminateDetail } from "../Services/EliminateService";
import DialogConfirm from "../../../components/DialogConfirm";
import styled from "@emotion/styled";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ImagePreview from "../../../components/ImagePreview";
import { IOrderDetail } from "../Services/OrderService";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";
import DialogCreate from "../../../components/DialogCreate";
import { marketDatas } from "../../../constants/dialogCreateInputs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { documentStatus } from "../../../constants/documentStatus";
import { useFileManagement } from "../../../utils/fileHandlers";


interface EliminateCreateViewProps {
  onUpdate: (data: IEliminateCreate, uploadFile: IUploadFile[], deleteFile: IUploadFile[], isDelete?: boolean) => void;
  eliminate: IEliminate;
  submitDicisionEliminate: (id: string, status: string, orderId: string, totalEliminateWeight: number, totalSalesWeight: number) => void;
  isLoading: boolean;
  user: IUserLogined;
  permission: IUserDetail;
  markets: IMarket[];
  eliminateId: string;
  onCreateDialog?: (data: any, type: string) => void;
  onFetchData?: (id?: any, type?: string) => void;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
}

interface IGradeCounts {
  [key: string]: number;
}
const filterOptions = createFilterOptions<any>();
const EliminateCreateView: FC<EliminateCreateViewProps> = (props) => {
  const userGroup = props.user.userGroup;
  const { setValue, formState: { errors }, getValues, control, watch, reset, handleSubmit } = useForm<IEliminateCreate>({});
  const navigate = useNavigate();

  const [isReject, setIsReject] = useState(false);
  const location = useLocation();
  const { from } = location.state || { from: null };
  const isFromStatus = from === 'status' || (from === "order" && documentStatus[props?.eliminate?.status]?.name);
  const isDisable = isFromStatus ? true : false;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const order = props.eliminate?.order;

  const {
    selectedFiles,
    images,
    pdfFiles,
    deleteImages,
    setImages,
    setPdfFiles,
    setSelectedFiles,
    setDeleteImages,
    handleFileChange,
    handleFileRemove,
    handleFileRemoveImageList,
    handleFileRemovePdfList,
  } = useFileManagement();

  const { fields } = useFieldArray({
    control,
    name: "eliminateDetails",
  });

  const [openCreateDailog, setOpenCreateDailog] = useState({ open: false, datas: [], type: "", title: "" });

  const [name, setName] = useState('');

  const _marketDatas: any = marketDatas({
    provinceData: props?.provinceData || [],
    districtData: props?.districtData || [],
    subDistrictData: props?.subDistrictData || [],
    name: name,
  }) || [];

  type GroupedFieldsType = {
    1: IEliminateDetail[];
    2: IEliminateDetail[];
    3: IEliminateDetail[];
  };

  const [totalBeforeEliminate, setTotalBeforeEliminate] = useState(0);
  const [totalAfterEliminate, setTotalAfterEliminate] = useState(0);
  const [totalSell, setTotalSell] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalEliminate, setTotalEliminate] = useState(0);
  const [groupedFields, setGroupedFields] = useState<GroupedFieldsType>({
    1: [],
    2: [],
    3: [],
  });

  const type = [
    { value: 1, name: "คัดขาย" },
    { value: 2, name: "คัดทิ้ง" },
  ];

  const [nameType, setNameType] = useState("");
  watch('eliminateDetails');

  useEffect(() => {
    handleSetRow(props.eliminate);
    setImages(props.eliminate.image || []);
    setPdfFiles(props.eliminate.file || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eliminate]);

  useEffect(() => {
    calTotalWeightAfterEliminate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSell, totalEliminate]);


  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });


  const onSubmit = (type: "create" | "delete" | "approve" | "reject") => {
    if (type === "delete") {
      setOpenConfirm({
        open: true,
        title: "ลบข้อมูล",
        message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
        color: "error",
        type: "delete",
      });
    } else {
      handleSubmit(() => {
        setOpenConfirm({
          open: true,
          title: isFromStatus ? type === 'reject' ? "ปฏิเสธรายการ" : "อนุมัติรายการ" : "บันทึกข้อมูล",
          message: isFromStatus ? type === 'reject' ? "คุณต้องการปฏิเสธรายการนี้ใช่หรือไม่" : "คุณต้องการอนุมัติรายการนี้ใช่หรือไม่" : "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
          color: type === "reject" ? "error" : "primary",
          type: "create",
        });
      })();
    }
  };

  const onReject = () => {
    setIsReject(true);
    onSubmit("reject");
  };

  const onApprove = () => {
    setIsReject(false);
    onSubmit("approve");
  };

  const onFormSubmit = (data: IEliminateCreate) => {
    if (props.eliminateId) {
      props.onUpdate({ ...data, status: isFromStatus ? isReject ? "rejected" : "approved" : "waiting" }, selectedFiles, deleteImages);
    }
    setIsReject(false);
    setSelectedFiles([]);
    setDeleteImages([]);
  };

  const onDelete = () => {
    const data = getValues();
    props.onUpdate(data, selectedFiles, deleteImages, true);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const onImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setOpenImage(true);
  };

  const handleSetRow = (data: IEliminate) => {
    const groupedData: { [key: string]: IEliminateDetail[]; } = {};

    // Function to group details by docNo
    const groupByDocNo = (items: IEliminateDetail[]) => {
      items.forEach(item => {
        const docNo = item.docNo || 'ไม่มีเลขที่เอกสาร';
        if (!groupedData[docNo]) {
          groupedData[docNo] = [];
        }
        groupedData[docNo].push(item);
      });
    };

    if (data.farmEliminate) {
      groupByDocNo(data.farmEliminate);
    }
    if (data.inspectionEliminate) {
      groupByDocNo(data.inspectionEliminate);
    }
    if (data.bakeEliminate) {
      groupByDocNo(data.bakeEliminate);
    }

    // Flatten the grouped data back into an array
    const _datas: IEliminateDetail[] = [];
    Object.entries(groupedData).forEach(([docNo, items]) => {
      items.forEach(item => {
        _datas.push({
          ...item,
        });
      });
    });

    let _totalBeforeEliminate = 0;
    let _totalSell = 0;
    let _totalEliminate = 0;
    let _totalPrice = 0;

    _datas.forEach(item => {
      if (!item.isDelete) {
        _totalBeforeEliminate += item.eliminateWeight ?? 0;
        if (item.eliminateType === 1) {
          _totalSell += Number(item.resultWeight) ?? 0;
          _totalPrice += Number(item.price) ?? 0;
        } else if (item.eliminateType === 2) {
          _totalEliminate += Number(item.resultWeight) ?? 0;
        }
      }
    });

    reset({
      eliminateDetails: _datas || [],
    });
    setTotalBeforeEliminate(_totalBeforeEliminate);
    setTotalSell(_totalSell);
    setTotalEliminate(_totalEliminate);
    setTotalPrice(_totalPrice);
    handleGroupedFields(_datas);
    setValue("eliminateDetails", _datas);
    setValue("note", data.note);
  };


  const calTotalSell = () => {
    let _totalSell = 0;
    fields.forEach((item, index) => {
      if (watch(`eliminateDetails.${index}.eliminateType`) === 1 && !item.isDelete) {
        const _total = watch(`eliminateDetails.${index}.resultWeight`);
        _totalSell += Number(_total) ?? 0;

      }
    });
    setTotalSell(_totalSell);
  };

  const calTotalEliminate = () => {
    let _totalEliminate = 0;
    fields.forEach((item, index) => {
      if (watch(`eliminateDetails.${index}.eliminateType`) === 2 && !item.isDelete) {
        const _total = watch(`eliminateDetails.${index}.resultWeight`);
        _totalEliminate += Number(_total) ?? 0;
      }
    });
    setTotalEliminate(_totalEliminate);
  };


  const calTotalWeightAfterEliminate = () => {
    let _totalAfterEliminate = 0;
    _totalAfterEliminate = totalBeforeEliminate - (totalSell + totalEliminate);
    setTotalAfterEliminate(_totalAfterEliminate);
  };

  const calTotalPrice = () => {
    let _totalPrice = 0;
    fields.forEach((item, index) => {
      if (watch(`eliminateDetails.${index}.eliminateType`) === 1 && !item.isDelete) {
        const _price = watch(`eliminateDetails.${index}.price`);
        _totalPrice += Number(_price) ?? 0;
      }
    });
    setTotalPrice(_totalPrice);
  };


  const handleGroupedFields = (fields: IEliminateDetail[]) => {
    const _groupedFields = {
      1: fields.filter(item => item.type === 1 && item.isDelete === false),
      2: fields.filter(item => item.type === 2 && item.isDelete === false),
      3: fields.filter(item => item.type === 3 && item.isDelete === false),
    };
    setGroupedFields(_groupedFields);
  };

  const handleOpenCreateDailog = (name: string) => {
    setNameType(name);
    switch (name) {
      case "market": setOpenCreateDailog(
        {
          open: true, datas: [], type: "market", title: "สร้างตลาด"
        }
      );
        break;
    }
  };

  useEffect(() => {
    handleOpenCreateDailog(nameType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.districtData, props.subDistrictData, props.provinceData]);

  const renderFields = (group: IEliminateDetail[], groupIndex: number) => {
    const groupedByDocNo: { [docNo: string]: IEliminateDetail[]; } = {};

    // Grouping details by docNo
    group.forEach(item => {
      const key = item.docNo || 'ไม่มีเลขที่เอกสาร';
      if (!groupedByDocNo[key]) {
        groupedByDocNo[key] = [];
      }
      groupedByDocNo[key].push(item);
    });

    // Get sorted entries of groupedByDocNo based on keys
    const sortedEntries = Object.entries(groupedByDocNo).sort(([a], [b]) => a.localeCompare(b));

    let currentItemCount = 0;

    return sortedEntries.map(([docNo, items]) => (
      <React.Fragment key={docNo}>
        {/* Render docNo header only for the first item in the group */}
        <Grid item xs={12}>
          <Typography sx={{ fontSize: ".95rem" }}>
            {docNo ? `เลขที่เอกสาร: ${docNo}` : "ไม่มีเลขที่เอกสาร"}
          </Typography>
        </Grid>

        {items.map((item, itemIndex) => {
          if (item.isDelete) return null;
          const currentIndex = groupIndex + currentItemCount;

          currentItemCount++;
          return (
            <React.Fragment key={item.id}>
              {(!isMobile) && (
                <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                  <IconButton
                    color="error"
                    onClick={() => deleteRow(item.id?.toString() ?? '')}
                    disabled={isDisable}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={2} lg={2} alignSelf={'center'}>
                <Controller
                  name={`eliminateDetails.${currentIndex}.eliminateWeight`}
                  control={control}
                  render={({ field }) => (
                    <Typography>{item.label} : {thaiFormatNumber(field.value?.toString() || '0')}</Typography>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1.5} lg={1.5} textAlign={'center'}>
                <Controller
                  name={`eliminateDetails.${currentIndex}.resultWeight`}
                  rules={{
                    required: true,
                    max: { value: item.eliminateWeight ?? 0, message: "กรุณาระบุจำนวนไม่มากกว่าตอนคัดออก" },
                    min: { value: 0, message: "กรุณาระบุจำนวนไม่น้อยกว่า 0" }
                  }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      disabled={isDisable}
                      fullWidth
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      label="จำนวน (Kg)"
                      {...field}
                      error={!!errors.eliminateDetails?.[currentIndex]?.resultWeight}
                      helperText={errors.eliminateDetails?.[currentIndex]?.resultWeight?.message}
                      onChange={(e) => {
                        field.onChange(e);
                        calTotalSell();
                        calTotalEliminate();
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1.5} lg={1.5}>
                <Controller
                  name={`eliminateDetails.${currentIndex}.eliminateType`}
                  control={control}
                  rules={{ required: 'กรุณาเลือกประเภทการคัด' }}
                  render={({ field }) => (
                    <FormControl fullWidth error={!!errors.eliminateDetails?.[currentIndex]?.eliminateType}>
                      <InputLabel id="status-select-label">คัดขาย/ทิ้ง</InputLabel>
                      <Select
                        disabled={isDisable}
                        labelId="status-select-label"
                        id="status-select"
                        value={field.value}
                        label="คัดขาย/ทิ้ง"
                        onChange={(event) => {
                          const newValue = event.target.value;
                          field.onChange(newValue);
                          calTotalSell();
                          calTotalPrice();
                          calTotalEliminate();
                        }}
                      >
                        {type.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.eliminateDetails?.[currentIndex]?.eliminateType && (
                        <FormHelperText>
                          {errors.eliminateDetails[currentIndex]?.eliminateType?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              {watch(`eliminateDetails.${currentIndex}.eliminateType`) === 1 ? (
                <>
                  <Grid item xs={12} sm={12} md={1.5} lg={1.5}>
                    <Controller
                      name={`eliminateDetails.${currentIndex}.market`}
                      control={control}
                      rules={{ required: 'กรุณาเลือกตลาด' }}
                      render={({ field }) => {
                        const selectedMarket = Array.isArray(field.value)
                          ? props.markets.find(market => market.id === (field.value as any[])[0]?.id)
                          : props.markets.find(market => market.id === field.value) || null;

                        return (
                          <Autocomplete
                            {...field}
                            disabled={isDisable}
                            options={props.markets}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                            filterOptions={(options, params) => {
                              const filtered = filterOptions(options, params);
                              if (params.inputValue !== '') {
                                filtered.push({
                                  name: `สร้าง '${params.inputValue}'`,
                                  id: 'create',
                                });
                                setName(params.inputValue);
                              }
                              return filtered;
                            }}
                            renderOption={(props, option) => {
                              const { key, ...rest } = props;
                              return (
                                <Typography key={key} {...rest} variant="body2" >{option.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                  <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                                </Box> : option.name}</Typography>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="เลือกตลาด"
                                variant="outlined"
                                error={!!errors.eliminateDetails?.[currentIndex]?.market}
                                helperText={errors.eliminateDetails?.[currentIndex]?.market?.message}
                              />
                            )}
                            onChange={(event, newValue) => {
                              if (newValue?.id === "create") {
                                handleOpenCreateDailog("market");
                              } else { field.onChange(newValue ? newValue.id : null); }
                            }}
                            value={selectedMarket || null}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={1.5} lg={1.5}>
                    <Controller
                      name={`eliminateDetails.${currentIndex}.price`}
                      control={control}
                      rules={{
                        required: 'กรุณาระบุราคา',
                        min: { value: 0, message: 'ราคาไม่สามารถติดลบได้' }
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ราคา"
                          type="number"
                          fullWidth
                          error={!!errors.eliminateDetails?.[currentIndex]?.price}
                          helperText={errors.eliminateDetails?.[currentIndex]?.price?.message}
                          disabled={isDisable}
                          InputProps={{ inputProps: { min: 0 } }}
                          onChange={(e) => {
                            field.onChange(e);
                            calTotalPrice();
                          }}
                        />
                      )}
                    />
                  </Grid>


                  <Grid item xs={12} sm={12} md={1.5} lg={1.5}>
                    <Controller
                      name={`eliminateDetails.${currentIndex}.assignee`}
                      control={control}
                      rules={{ required: 'กรุณาระบุผู้รับผิดชอบ' }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ผู้รับผิดชอบ"
                          fullWidth
                          error={!!errors.eliminateDetails?.[currentIndex]?.assignee}
                          helperText={errors.eliminateDetails?.[currentIndex]?.assignee?.message}
                          disabled={isDisable}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={1.5} lg={1.5}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                        <Controller
                          name={`eliminateDetails.${currentIndex}.date`}
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  error: !!errors.eliminateDetails?.[currentIndex]?.date,
                                  helperText: errors.eliminateDetails?.[currentIndex]?.date?.message,
                                }
                              }}
                              format="DD/MM/YYYY"
                              {...field}
                              defaultValue={dayjs(new Date())}
                              value={field.value ? dayjs(field.value) : dayjs(new Date())}
                              onChange={(date) => field.onChange(date)}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>

                </>

              ) : (
                <Grid item xs={12} sm={12} md={6} lg={6} />
              )}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    ));
  };



  const deleteRow = (id?: string) => {
    const value = getValues("eliminateDetails");
    if (value && value.length > 0) {
      let newValue = [...value];
      if (id) {
        const itemIndex = value.findIndex(item => item.id === id);
        if (itemIndex !== -1) {
          const itemToDelete = value[itemIndex];
          if (itemToDelete.eliminateWeight) {
            if (itemToDelete.eliminateType === 1) {
              const _totalSell = totalSell - (itemToDelete.resultWeight ?? 0);
              const _totalPrice = totalPrice - (itemToDelete.price ?? 0);
              setTotalSell(_totalSell);
              setTotalPrice(_totalPrice);
            } else if (itemToDelete.eliminateType === 2) {
              const _totalEliminate = totalEliminate - (itemToDelete.resultWeight ?? 0);
              setTotalEliminate(_totalEliminate);
            }
            const _totalBeforeEliminate = totalBeforeEliminate - (itemToDelete.eliminateWeight ?? 0);
            setTotalBeforeEliminate(_totalBeforeEliminate);
            const _totalAfterEliminate = _totalBeforeEliminate - (totalSell + totalEliminate);
            setTotalAfterEliminate(_totalAfterEliminate);
          }
          newValue[itemIndex].isDelete = true;
        }
      } else {
        const lastItem = newValue[newValue.length - 1];
        if (lastItem) {
          if (lastItem.eliminateWeight) {
            if (lastItem.eliminateType === 1) {
              const _totalSell = totalSell - (lastItem.resultWeight ?? 0);
              const _totalPrice = totalPrice - (lastItem.price ?? 0);
              setTotalSell(_totalSell);
              setTotalPrice(_totalPrice);
            } else if (lastItem.eliminateType === 2) {
              const _totalEliminate = totalEliminate - (lastItem.resultWeight ?? 0);
              setTotalEliminate(_totalEliminate);
            }
            const _totalBeforeEliminate = totalBeforeEliminate - (lastItem.eliminateWeight ?? 0);
            setTotalBeforeEliminate(_totalBeforeEliminate);
            const _totalAfterEliminate = _totalBeforeEliminate - (totalSell + totalEliminate);
            setTotalAfterEliminate(_totalAfterEliminate);
          }
          newValue.pop();
        }
      }
      setValue("eliminateDetails", newValue);
      handleGroupedFields(newValue);
    }
  };

  const groupByGrade = (orderDetails: IOrderDetail[]) => {
    const gradeCounts: IGradeCounts = orderDetails.reduce<IGradeCounts>((acc, item) => {
      const gradeName = item.grade?.name ?? '';
      if (!acc[gradeName]) {
        acc[gradeName] = 0;
      }
      acc[gradeName]++;
      return acc;
    }, {});

    let gradeName = Object.entries(gradeCounts)
      .map(([name]) => `${name}`)
      .sort()
      .join(', ');

    return gradeName;
  };


  return (
    <Box component={'div'}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="back"
            onClick={() => navigate("/process-eliminate")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          {from === "order" ? <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>คัดขาย/ทิ้ง</Typography>
            <Link component="button" onClick={() => navigate(`/process-order-edit/${order?.id}`)}>
              ใบคำสั่งซื้อ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.eliminateId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs> :
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Typography>คัดขาย/ทิ้ง</Typography>
              <Link component="button" onClick={() => navigate("/process-eliminate")}>
                รายการ
              </Link>
              <Typography variant="h6" color="text.primary">
                {props.eliminateId ? "แก้ไข" : "สร้าง"}
              </Typography>
            </Breadcrumbs>}
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              {props.eliminateId &&
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} mb={3}>
                      <Typography variant="h6">{props.eliminate?.order?.projectName
                      } </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                      <Typography>{`สินค้า : ${props.eliminate.order?.product?.name}`}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                      <Typography>{`เกรด : ${props.eliminate.order?.orderDetails ? groupByGrade(props.eliminate.order.orderDetails) : ''}`}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                      <Typography>{`สถานะ : ${props.eliminate.order?.isComplete ? 'เสร็จสิ้น' : 'รอดำเนินการ'}`}</Typography>
                    </Grid>
                  </Grid>
                </>
              }
              <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                <Divider />
              </Grid>
              <Box component="form" onSubmit={handleSubmit(onFormSubmit)} mt={2}>
                <Grid container spacing={2}>
                  {groupedFields[1] && groupedFields[1].length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography fontSize={16} fontWeight={'bold'}>รับจากสวน</Typography>
                      </Grid>
                      {renderFields(groupedFields[1], 0)}
                      <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                        <Divider />
                      </Grid>
                    </>
                  )}

                  {groupedFields[2] && groupedFields[2].length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography fontSize={16} fontWeight={'bold'}>ตรวจสอบคุณภาพ</Typography>
                      </Grid>
                      {renderFields(groupedFields[2], groupedFields[1].length)}
                      <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                        <Divider />
                      </Grid>
                    </>
                  )}

                  {groupedFields[3] && groupedFields[3].length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography fontSize={16} fontWeight={'bold'}>อบ</Typography>
                      </Grid>
                      {renderFields(groupedFields[3], groupedFields[1].length + groupedFields[2].length)}
                    </>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                  <Grid container spacing={2}>
                    {isMobile && (
                      <Grid item xs={4} sm={4}>
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{ width: '100%' }}
                          type="button"
                          startIcon={<RemoveCircleIcon />}
                          onClick={() => deleteRow()}
                        >
                          ลบ
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} mt={2} mb={2}>
                  <Divider />
                </Grid>

                <Grid container spacing={2} justifyContent={'space-between'}>
                  <Grid item xs={12} sm={7} md={7} lg={7} >
                    <Controller
                      name={`note`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          multiline
                          type="text"
                          label="หมายเหตุ"
                          variant="outlined"
                          disabled={isDisable}
                          fullWidth
                          rows={3}
                          value={field.value}
                          onChange={field.onChange}
                          InputLabelProps={{ shrink: getValues("note") ? true : undefined }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={5} md={5} lg={5}>
                    <Grid container spacing={2}>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography>น้ำหนักก่อนคัดขาย/ทิ้ง (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography>{thaiFormatNumber(parseFloat(totalBeforeEliminate.toString()).toFixed(2))}</Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography>น้ำหนักคัดขายทั้งหมด (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography>{thaiFormatNumber(parseFloat(totalSell.toString()).toFixed(2))}</Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography>น้ำหนักคัดทิ้งทั้งหมด (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography>{thaiFormatNumber(parseFloat(totalEliminate.toString()).toFixed(2))}</Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography>น้ำหนักคงเหลือ (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography>{thaiFormatNumber(parseFloat(totalAfterEliminate.toString()).toFixed(2))}</Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography>รวมมูลค่าคัดขาย (บาท)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography>{thaiFormatNumber(parseFloat(totalPrice.toString()).toFixed(2))}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={7} md={7} lg={7}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography>เอกสาร</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Button component="label" variant="outlined" disabled={isDisable} startIcon={<UploadFileIcon />}>
                        เลือกไฟล์
                        <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png, .pdf" multiple onChange={handleFileChange} />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png, .pdf </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {selectedFiles.length > 0 && (
                        <Box component={'div'} mt={1}>
                          {selectedFiles.map((file: any, index: number) => (
                            <Grid item xs={12} sm={12} md={12} key={`sf-${index.toString()}`} mt={1}>
                              <Grid container >
                                <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                  <IconButton size="small" color="error" onClick={() => handleFileRemove(index)}>
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11} sm={11} md={11} lg={11}>
                                  {file.type === 'application/pdf' ? (
                                    <Typography variant="body2">
                                      <a
                                        href={URL.createObjectURL(file)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "inherit", cursor: "pointer" }}
                                      >
                                        {file.name}
                                      </a>
                                    </Typography>
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                      style={{ maxWidth: "50%", height: "auto", cursor: "pointer" }}
                                      onClick={() => onImageClick(URL.createObjectURL(file))}
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {images && (
                        <Box component={'div'} mt={1}>
                          {images.map((file: any, index: number) => (
                            <Grid item xs={12} sm={12} md={12} key={`im-${index.toString()}`} mt={1}>
                              <Grid container >
                                <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                  <IconButton size="small"
                                    color="error" onClick={() => handleFileRemoveImageList(file)}>
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11} sm={11} md={11} lg={11}>
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}/uploads/${(file).name
                                      }`}
                                    alt="Selected"
                                    style={{ maxWidth: "50%", height: "auto", cursor: 'pointer' }}
                                    onClick={() => onImageClick(`${process.env.REACT_APP_API_URL}/uploads/${(file).name}`)}

                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      {pdfFiles.length > 0 && (
                        <Box component={'div'} mt={1}>
                          {pdfFiles.map((file: any, index: number) => (
                            <Grid item xs={12} key={`pdf-${index}`} mt={1}>
                              <Grid container>
                                <Grid item xs={1} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                  <IconButton size="small" color="error" onClick={() => handleFileRemovePdfList(file)}>
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11}>
                                  <a
                                    href={`${process.env.REACT_APP_API_URL}/uploads/${file.name}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "inherit", cursor: "pointer" }}
                                  >
                                    {file.originalName}
                                  </a>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography>ผู้ทำรายการ : {props.eliminate?.createBy?.firstName} {props.eliminate?.createBy?.lastName}</Typography>
                    <Typography>ผู้อนุมัติ/ปฏิเสธ: {props.eliminate?.approveBy?.firstName} {props.eliminate?.approveBy?.lastName}</Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                  <Divider />
                </Grid>

                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} justifyContent={"end"}>
                    <Stack spacing={2} direction="row">
                      {isFromStatus ? (
                        <>
                          {userGroup?.canApprove && (props.eliminate?.status === "waiting") ? (
                            <>
                              <Button
                                variant="contained"
                                fullWidth
                                color="error"
                                onClick={onReject}
                              >
                                ปฏิเสธ
                              </Button>
                              <Button
                                variant="contained"
                                color="success"
                                fullWidth
                                onClick={onApprove}
                                style={{ marginLeft: '8px' }}
                              >
                                อนุมัติ
                              </Button>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {props.eliminate?.status === "waiting" || props.eliminate?.status === "draft" ? (
                            <>
                              <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => navigate("/process-eliminate")}
                              >
                                ยกเลิก
                              </Button>
                              {props.permission.create || (props.permission.edit && props.eliminate) ? (
                                <Button
                                  id="submit"
                                  variant="contained"
                                  onClick={() => onSubmit("create")}
                                >
                                  บันทึก
                                </Button>
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            onDelete();
          } else {
            handleSubmit(onFormSubmit)();
          }
        }}
      />

      <ImagePreview
        open={openImage}
        imageSrc={selectedImage}
        onClose={() => setOpenImage(false)}
      />

      <DialogCreate datas={_marketDatas}
        open={openCreateDailog?.open}
        title={openCreateDailog?.title}
        type={openCreateDailog?.type}
        onClose={() => {
          setOpenCreateDailog({ ...openCreateDailog, open: false });
          setName("");
        }}
        onSubmit={(data, type) => { if (props?.onCreateDialog) { props?.onCreateDialog(data, type); } }} onFetchData={(id, type) => {
          if (props?.onFetchData) { props?.onFetchData(id, type); }
        }}
        onCreateDialog={(data, type) => { if (props?.onCreateDialog) { props?.onCreateDialog(data, type); } }}
      />
    </Box>
  );
};

export default EliminateCreateView;