import { FC, useEffect, useState } from "react";
import { Box, Breadcrumbs, Radio, Button, ButtonProps, Card, CardContent, Divider, Grid, IconButton, Link, Stack, TextField, Typography, FormControl, useMediaQuery, Autocomplete, createFilterOptions, FormControlLabel, Checkbox, RadioGroup } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import styled from "@emotion/styled";
import DialogConfirm from "../../../components/DialogConfirm";
import { ISupplier } from "../../Supplier/Services/SupplierService";
import { IMaterial } from "../Services/MaterialsService";
import { IDistrict, IProvince, IReceive, IReceiveDetail, ISubDistrict, IUploadFile } from "../Services/ReceiveService";
import { IWarehouse } from "../Services/WareshousesService";
import { theme } from "../../../themes";
import ImagePreview from "../../../components/ImagePreview";
import { IUserDetail, IUserLogined } from "../../Login/Services/LoginService";
import DialogCreate from "../../../components/DialogCreate";
import { ISupplierType } from "../../Supplier/Services/SupplierTypeService";
import { supplierDatas, warehouseDatas } from "../../../constants/dialogCreateInputs";
import MaterialsCreateView from "./MaterialCreateView";
import { useFileManagement } from "../../../utils/fileHandlers";

interface ReceiveCreateViewProps {
  onCreate: (data: IReceive, uploadFile: IUploadFile) => void;
  onUpdate: (id: string, data: IReceive, uploadFile: IUploadFile[], deleteFile: IUploadFile[]) => void;
  onDelete: (data: IReceive) => void;
  receiveData?: IReceive;
  warehousesData?: IWarehouse[];
  suppliersData?: ISupplier[];
  materialsData?: IMaterial[];
  receiveId: string;
  permission: IUserDetail;
  user: IUserLogined;
  supplierTypesOptions?: ISupplierType[];
  materialOptions?: IMaterial[];
  warehouseOptions?: IWarehouse[];
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onCreateDialog: (data: any, type: string) => void;
  onFetchData: (id?: any, type?: string) => void;
}

const filterOptions = createFilterOptions<any>();
const ReceiveCreateView: FC<ReceiveCreateViewProps> = (props) => {
  const userGroup = props.user.userGroup;
  const { control, register, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm<IReceive>();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });
  const [isReject, setIsReject] = useState(false);
  const location = useLocation();
  const { from } = location.state || { from: null };
  const isFromStatus = from === 'status';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const navigate = useNavigate();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "details"
  });
  const [openCreateDailog, setOpenCreateDailog] = useState({ open: false, datas: [], type: "", title: "" });
  const [nameType, setNameType] = useState("");
  const [name, setName] = useState("");
  const [vatType, setVatType] = useState("noneVat");
  const [vatTotal, setVatTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalAfterDiscound, setTotalAfterDiscound] = useState(0);
  const {
    selectedFiles,
    images,
    pdfFiles,
    deleteImages,
    setImages,
    setPdfFiles,
    setSelectedFiles,
    setDeleteImages,
    handleFileChange,
    handleFileRemove,
    handleFileRemoveImageList,
    handleFileRemovePdfList,
  } = useFileManagement();


  // NOTE: supplier input
  const datasInChildCreateDialog = [{ type: "text", label: "ประเภท", name: "name", xs: 12, sm: 12, md: 12, lg: 12, defaultValue: "" },];
  const _supplierDatas: any = supplierDatas({
    provinceData: props?.provinceData || [],
    districtData: props?.districtData || [],
    subDistrictData: props?.subDistrictData || [],
    datasInChildCreateDialog: datasInChildCreateDialog || [],
    supplierTypesOptions: props?.supplierTypesOptions || [],
    name: name,
  }) || [];

  // NOTE: warehouse input
  const _warehouseDatas: any = warehouseDatas({
    provinceData: props?.provinceData || [],
    districtData: props?.districtData || [],
    subDistrictData: props?.subDistrictData || [],
  }) || [];

  // NOTE: fix state name
  const handleOpenCreateDailog = (_name: string) => {
    setNameType(_name);
    switch (_name) {
      case "supplier": setOpenCreateDailog(
        {
          open: true, datas: _supplierDatas, type: "supplier", title: "สร้าง Supplier"
        }
      );
        break;
      case "material": setOpenCreateDailog(
        {
          open: true, datas: [], type: "material", title: "เพิ่มรายการวัสดุ"
        }
      );
        break;
      case "warehouse": setOpenCreateDailog(
        {
          open: true, datas: _warehouseDatas, type: "warehouse", title: "สร้างคลังวัสดุ"
        }
      );
    }
  };

  const onFormSubmit = (data: IReceive) => {
    if (vatType === "noneVat") {
      data.noneVat = true;
      data.includeVat = false;
      data.excludeVat = false;
      data.vat = 0;
    } else if (vatType === "includeVat") {
      data.noneVat = false;
      data.includeVat = true;
      data.excludeVat = false;
      data.vat = vatTotal;
    } else {
      data.noneVat = false;
      data.includeVat = false;
      data.excludeVat = true;
      data.vat = vatTotal;
    }
    if (props.receiveId) {
      props.onUpdate(props.receiveId, { ...data, status: isFromStatus ? isReject ? "rejected" : "approved" : "waiting" }, selectedFiles, deleteImages);
    } else {
      props.onCreate(data, selectedFiles as any);
    }
    setIsReject(false);
    setSelectedFiles([]);
    setDeleteImages([]);
    reset({
      details: [{
        order: 1,
        material: {} as IMaterial,
        qty: 0,
        warehouse: {} as IWarehouse,
        unitPrice: 0,
        totalPricePerRow: 0,
      }],
      totalPrice: 0,
      receivedDate: dayjs().toDate(),
    });
  };

  const onDelete = () => {
    const data = getValues();
    props.onDelete({ ...data, isDelete: true });
    reset({
      details: [{
        order: 1,
        material: {} as IMaterial,
        qty: 0,
        warehouse: {} as IWarehouse,
        unitPrice: 0,
        totalPricePerRow: 0,
      }],
      totalPrice: 0,
      receivedDate: dayjs().toDate(),
    });
  };

  const onSubmit = (type: "create" | "delete" | "approve" | "reject") => {
    if (type === "delete") {
      setOpenConfirm({
        open: true,
        title: "ลบข้อมูล",
        message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
        color: "error",
        type: "delete",
      });
    } else {
      handleSubmit(() => {
        setOpenConfirm({
          open: true,
          title: isFromStatus ? type === 'reject' ? "ปฏิเสธรายการ" : "อนุมัติรายการ" : "บันทึกข้อมูล",
          message: isFromStatus ? type === 'reject' ? "คุณต้องการปฏิเสธรายการรับวัสดุนี้ใช่หรือไม่" : "คุณต้องการอนุมัติรายการรับวัสดุนี้ใช่หรือไม่" : "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
          color: type === "reject" ? "error" : "primary",
          type: "create",
        });
      })();
    }
  };

  const onReject = () => {
    setIsReject(true);
    onSubmit("reject");
  };

  const onApprove = () => {
    setIsReject(false);
    onSubmit("approve");
  };

  const calculateTotalPricePerRow = (index: number) => {
    const qty = Number(getValues(`details.${index}.qty`) || 0);
    const unitPrice = Number(getValues(`details.${index}.unitPrice`) || 0);
    const totalPricePerRow = qty * unitPrice;

    return totalPricePerRow.toFixed(2);
  };


  const onQtyChange = (index: number, value: string) => {
    const newQty = parseInt(value, 10) || 0;
    const currentUnitPrice = Number(getValues(`details.${index}.unitPrice`) || 0);
    const newTotalPricePerRow = newQty * currentUnitPrice;

    setValue(`details.${index}.totalPricePerRow`, newTotalPricePerRow);
    calculateTotalPrice();
  };

  const onUnitPriceChange = (index: number, value: string) => {
    const newUnitPrice = parseFloat(value) || 0;
    const currentQty = Number(getValues(`details.${index}.qty`) || 0);
    const newTotalPricePerRow = currentQty * newUnitPrice;

    setValue(`details.${index}.totalPricePerRow`, newTotalPricePerRow as never);
    calculateTotalPrice();
    calculateTotalAfterDiscount(String(getValues('discount')));
  };


  const calculateTotalPrice = () => {
    const totalPricePerRowValues = getValues('details').map((item: IReceiveDetail) => {
      const qty = Number(item.qty || 0);
      const unitPrice = Number(item.unitPrice || 0);
      return qty * unitPrice;
    });

    const totalPrice = totalPricePerRowValues.reduce((acc: number, curr: number) => acc + curr, 0);
    setValue('totalPrice', totalPrice as never);
    calculateVat(vatType);
  };

  const onImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setOpenImage(true);
  };

  const calculateVat = (type: string) => {
    const totalPrice = Number(getValues('totalPrice') || 0);
    const discount = Number(getValues('discount') || 0);
    if (type !== "noneVat") {
      const vat = ((totalPrice - discount) * 7) / 100;
      setVatTotal(vat);
      setGrandTotal((totalPrice - discount) + vat);
    } else {
      setValue('vat', 0);
      setGrandTotal(totalPrice - discount);
      setVatTotal(0);
    }
  };

  const calculateTotalAfterDiscount = (value: string) => {
    const totalPrice = Number(getValues('totalPrice') || 0);
    const discount = Number(value) || 0;
    const totalAfterDiscount = totalPrice - discount;
    setTotalAfterDiscound(totalAfterDiscount);
    calculateVat(vatType);
  };

  const handleSetVatType = (data: IReceive) => {
    let _vatType = "";
    switch (true) {
      case data.noneVat:
        _vatType = "noneVat";
        break;
      case data.includeVat:
        _vatType = "includeVat";
        break;
      case data.excludeVat:
        _vatType = "excludeVat";
        break;
      default:
        _vatType = "noneVat";
    }
    setVatType(_vatType);
    calculateVat(_vatType);
  };

  const handleDefaultValue = () => {
    const defaultValues = {
      details: [{
        order: 1,
        material: {} as IMaterial,
        qty: 0,
        warehouse: {} as IWarehouse,
        unitPrice: 0,
        totalPricePerRow: 0,
      }],
      totalPrice: 0,
      receivedDate: dayjs().format("YYYY-MM-DD"),
    };
    reset(defaultValues);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  useEffect(() => {
    if (props.receiveData && props.receiveId) {
      reset(props.receiveData);
      handleSetVatType(props.receiveData);
      calculateTotalAfterDiscount(String(props.receiveData.discount));
      setImages(props.receiveData.image || []);
      setPdfFiles(props.receiveData.file || []);
    } else {
      handleDefaultValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.receiveData]);

  useEffect(() => {
    handleOpenCreateDailog(nameType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.districtData, props.subDistrictData, props.supplierTypesOptions]);

  return (
    <Box component={'div'}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="back"
            onClick={() => navigate("/inventory-receive")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>รับเข้าวัสดุ</Typography>
            <Link component="button" onClick={() => navigate("/inventory-receive")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.receiveId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.receiveId && props.permission.delete) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => onSubmit("delete")}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              {props.receiveId && <Grid item xs={12} sm={12} md={12} lg={12} mb={3}>
                <Typography variant="h6">{props.receiveData?.docNo}</Typography>
              </Grid>}
              <Box component="form" onSubmit={handleSubmit(onFormSubmit)} mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                        <Controller
                          name="receivedDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  error: !!errors.receivedDate,
                                  helperText: errors.receivedDate ? "กรุณาระบุวันที่เอกสาร" : "",
                                }
                              }}
                              label="วันที่เอกสาร"
                              format="DD/MM/YYYY"
                              {...field}
                              defaultValue={dayjs(new Date())}
                              value={field.value ? dayjs(field.value) : dayjs(new Date())}
                              onChange={(date) => {
                                field.onChange(date ? date.format('YYYY-MM-DD') : null);
                              }}
                              disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Controller
                      name="supplier"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          value={value as ISupplier || null}
                          disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                          options={props.suppliersData?.filter(item => item.isOrchard === false) || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(_, newValue) => {
                            if (newValue?.id === "create") {
                              handleOpenCreateDailog("supplier");
                              onChange(null);
                            } else if (newValue?.id) { onChange(newValue); }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                              });
                              setName(params.inputValue);
                            }
                            return filtered;
                          }}
                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Supplier"
                              error={!!errors.supplier}
                              helperText={errors.supplier ? "กรุณาเลือก Supplier" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormControl fullWidth>
                      <TextField
                        label="ใบเสร็จ/ใบส่งของ"
                        {...register("poNumber", {
                          required: "กรุณาระบุใบเสร็จ/ใบส่งของ",
                        })}
                        disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                        error={!!errors.poNumber}
                        helperText={errors.poNumber ? errors.poNumber.message : ""}
                        InputLabelProps={{ shrink: getValues("poNumber") ? true : undefined }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="subtitle1">รายการวัสดุ</Typography>
                  </Grid>

                  {!isMobile && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>

                        </Grid>
                        <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
                          <Typography>ลำดับ</Typography>
                        </Grid>
                        <Grid item xs={3} sm={2} md={2} lg={2} justifyContent={'center'} display={'flex'}>
                          <Typography>วัสดุ</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography>จำนวน</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography>คลังปลายทาง</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={1} lg={1}>
                          <Typography>ราคาต่อหน่วย</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={1.5} lg={1.5}>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography>ราคารวม</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {fields.map((item, index) => (
                      <Grid container spacing={2} key={item.id} mb={1}>
                        {!isMobile && (
                          <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                            <IconButton
                              color="error"
                              disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                              onClick={() => {
                                remove(index);
                                calculateTotalPrice();
                                calculateTotalAfterDiscount(String(getValues('discount')));
                              }}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={0.5} lg={0.5} justifyContent={isMobile ? "left " : "center"} alignItems={'center'} display={'flex'}>
                          {isMobile && "รายการที่ "}{index + 1}
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <Controller
                            name={`details.${index}.material.id`}
                            control={control}
                            rules={{ required: 'กรุณาเลือกวัสดุ' }}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth error={!!errors.details?.[index]?.material?.id}>
                                <Autocomplete
                                  options={props.materialsData || []}
                                  disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                                  getOptionLabel={(option) => option.name || ""}
                                  onChange={(e, newValue) => {
                                    const selectedId = newValue?.id || "";
                                    if (selectedId === "create") {
                                      handleOpenCreateDailog("material");
                                      onChange(null);
                                    } else if (selectedId) {
                                      onChange(selectedId);
                                    }
                                  }}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  filterOptions={(options, params) => {
                                    const filtered = filterOptions(options, params);
                                    if (params.inputValue !== '') {
                                      filtered.push({
                                        name: `สร้าง '${params.inputValue}'`,
                                        id: 'create',
                                      });
                                      setName(params.inputValue);
                                    }
                                    return filtered;
                                  }}
                                  renderOption={(props, option) => {
                                    const { key, ...rest } = props;
                                    return (
                                      <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                        <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                                      </Box> : option.name}</Typography>
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={isMobile ? "วัสดุ" : ""}
                                      error={!!errors.details?.[index]?.material?.id}
                                      helperText={errors.details?.[index]?.material?.id && errors.details[index]?.material?.id?.message}
                                    />
                                  )}
                                  value={props.materialsData?.find(material => material.id === value) || null}
                                />
                              </FormControl>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <Controller
                            name={`details.${index}.qty`}
                            control={control}
                            rules={{
                              required: 'กรุณาระบุจำนวน',
                              min: { value: 0, message: 'จำนวนต้องไม่ติดลบ' },
                            }}
                            disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                type="number"
                                label={isMobile ? "จำนวน" : ""}
                                fullWidth
                                error={!!errors.details?.[index]?.qty}
                                helperText={
                                  errors.details?.[index]?.qty?.message || ''
                                }
                                InputProps={{ inputProps: { min: 0 } }}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  onQtyChange(index, e.target.value);
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <Controller
                            name={`details.${index}.warehouse.id`}
                            control={control}
                            rules={{ required: 'กรุณาเลือกคลังวัสดุ' }}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth error={!!errors.details?.[index]?.material?.id}>
                                <Autocomplete
                                  options={props.warehousesData || []}
                                  getOptionLabel={(option) => option.name || ""}
                                  disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                                  onChange={(e, newValue) => {
                                    const selectedId = newValue?.id || "";
                                    if (selectedId === "create") {
                                      handleOpenCreateDailog("warehouse");
                                      onChange(null);
                                    } else if (selectedId) {
                                      onChange(selectedId);
                                    }
                                  }}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  filterOptions={(options, params) => {
                                    const filtered = filterOptions(options, params);
                                    if (params.inputValue !== '') {
                                      filtered.push({
                                        name: `สร้าง '${params.inputValue}'`,
                                        id: 'create',
                                      });
                                      setName(params.inputValue);
                                    }
                                    return filtered;
                                  }}
                                  renderOption={(props, option) => {
                                    const { key, ...rest } = props;
                                    return (
                                      <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                        <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                                      </Box> : option.name}</Typography>
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={isMobile ? "คลังวัสดุ" : ""}
                                      error={!!errors.details?.[index]?.material?.id}
                                      helperText={errors.details?.[index]?.material?.id && errors.details[index]?.material?.id?.message}
                                    />
                                  )}
                                  value={props.warehousesData?.find(material => material.id === value) || null}
                                />
                              </FormControl>
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={1} lg={1}>
                          <Controller
                            name={`details.${index}.unitPrice`}
                            control={control}
                            disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                            rules={{
                              required: 'กรุณาระบุราคาต่อหน่วย',
                              min: { value: 0, message: 'ราคาต่อหน่วยต้องไม่ติดลบ' },
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                type="number"
                                label={isMobile ? "ราคาต่อหน่วย" : ""}
                                fullWidth
                                InputProps={{ inputProps: { min: 0 } }}
                                error={!!errors.details?.[index]?.unitPrice}
                                helperText={
                                  errors.details?.[index]?.unitPrice?.message || ''
                                }
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  onUnitPriceChange(index, e.target.value);
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={1.5} lg={1.5}>
                          <FormControl fullWidth>
                            <Controller
                              name={`details.${index}.isAvg`}
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                                      checked={field.value === true}
                                      onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                  }
                                  label={"เป็นราคาทุน"}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2.5} lg={2.5}>
                          <Controller
                            name={`details.${index}.totalPricePerRow`}
                            control={control}
                            rules={{
                              min: { value: 0, message: 'ราคารวมต้องไม่ติดลบ' },
                            }}
                            disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                type="number"
                                label={isMobile ? "ราคารวม" : ""}
                                fullWidth
                                value={props.receiveData?.status === "approved" || props.receiveData?.status === "rejected" ? item.totalPricePerRow : calculateTotalPricePerRow(index)}
                                InputProps={{ inputProps: { min: 0 }, readOnly: true }}
                                error={!!errors.details?.[index]?.totalPricePerRow}
                                helperText={
                                  errors.details?.[index]?.totalPricePerRow?.message || ''
                                }
                              />
                            )}
                          />
                        </Grid>

                      </Grid>
                    ))}
                    <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                      <Grid container spacing={2}>
                        {isMobile ? <>
                          <Grid item xs={4} sm={4} >
                            <Button
                              variant="outlined"
                              color="error"
                              sx={{ width: '100%' }}
                              disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                              type="button"
                              startIcon={<RemoveCircleIcon />}
                              onClick={() => {
                                remove(fields.length - 1);
                                calculateTotalPrice();
                                calculateTotalAfterDiscount(String(getValues('discount')));
                              }}

                            >
                              ลบ
                            </Button>
                          </Grid>
                          <Grid item xs={8} sm={8} >
                            <Button
                              variant="outlined"
                              sx={{ width: '100%' }}
                              disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                              type="button"
                              startIcon={<AddCircleIcon />}
                              onClick={() => append({
                                order: fields.length + 1,
                                material: {} as IMaterial,
                                qty: 0,
                                warehouse: {} as IWarehouse,
                                unitPrice: 0,
                                totalPricePerRow: 0,
                              })}
                            >
                              เพิ่มรายการ
                            </Button>

                          </Grid></> : <Grid item md={12} lg={12}>
                          <Button
                            variant="outlined"
                            sx={{ width: '100%' }}
                            disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                            type="button"
                            startIcon={<AddCircleIcon />}
                            onClick={() => append({
                              order: fields.length + 1,
                              material: {} as IMaterial,
                              qty: 0,
                              warehouse: {} as IWarehouse,
                              unitPrice: 0,
                              totalPricePerRow: 0,
                            })}
                          >
                            เพิ่มรายการ
                          </Button>

                        </Grid>
                        }

                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>


                <Grid item xs={12} sm={12} mt={2} mb={2} md={12} lg={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} md={7} lg={9}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography>เอกสาร</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Button component="label" variant="outlined" disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true} startIcon={<UploadFileIcon />}>
                            เลือกไฟล์
                            <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png, .pdf" multiple onChange={handleFileChange} />
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png, .pdf</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          {selectedFiles.length > 0 && (
                            <Box component={'div'} mt={1}>
                              {selectedFiles.map((file: any, index: number) => (
                                <Grid item xs={12} sm={12} md={12} key={`sf-${index.toString()}`} mt={1}>
                                  <Grid container>
                                    <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                      <IconButton size="small" disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true} color="error" onClick={() => handleFileRemove(index)}>
                                        <RemoveCircleIcon />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={11} sm={11} md={11} lg={11}>
                                      {file.type === 'application/pdf' ? (
                                        <Typography variant="body2">
                                          <a
                                            href={URL.createObjectURL(file)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "inherit", cursor: "pointer" }}
                                          >
                                            {file.name}
                                          </a>
                                        </Typography>
                                      ) : (
                                        <img
                                          src={URL.createObjectURL(file)}
                                          alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                          style={{ maxWidth: "50%", height: "auto", cursor: "pointer" }}
                                          onClick={() => onImageClick(URL.createObjectURL(file))}
                                        />
                                      )}
                                    </Grid>

                                  </Grid>
                                </Grid>
                              ))}
                            </Box>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          {images && (
                            <Box component={'div'} mt={1}>
                              {images.map((file: any, index: number) => (
                                <Grid item xs={12} sm={12} md={12} key={`im-${index.toString()}`} mt={1}>
                                  <Grid container >
                                    <Grid item xs={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                      <IconButton size="small" disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                                        color="error" onClick={() => handleFileRemoveImageList(file)}>
                                        <RemoveCircleIcon />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={11} >
                                      <img
                                        src={`${process.env.REACT_APP_API_URL}/uploads/${file.name}`}
                                        alt="Selected"
                                        style={{ maxWidth: "50%", height: "auto", cursor: 'pointer' }}
                                        onClick={() => onImageClick(`${process.env.REACT_APP_API_URL}/uploads/${file.name}`)}
                                      />

                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {pdfFiles.length > 0 && (
                            <Box component={'div'} mt={1}>
                              {pdfFiles.map((file: any, index: number) => (
                                <Grid item xs={12} key={`pdf-${index}`} mt={1}>
                                  <Grid container>
                                    <Grid item xs={1} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                      <IconButton size="small" color="error" onClick={() => handleFileRemovePdfList(file)}>
                                        <RemoveCircleIcon />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={11}>
                                      <a
                                        href={`${process.env.REACT_APP_API_URL}/uploads/${file.name}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "inherit", cursor: "pointer" }}
                                      >
                                        {file.originalName}
                                      </a>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                            </Box>
                          )}
                        </Grid>

                      </Grid>
                    </Grid>
                    <Grid item xs={5} sm={5} md={5} lg={3} >
                      <Grid container spacing={2}>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                          <Typography>รวมเป็นเงิน</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                          <Controller
                            name="totalPrice"
                            control={control}
                            render={({ field }) => (
                              <Typography>
                                {field.value ? (
                                  parseFloat(field.value.toString()).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  })
                                ) : (
                                  '0.00'
                                )}
                              </Typography>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Typography>ส่วนลด</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Controller
                            name="discount"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                type="number"
                                fullWidth
                                variant="outlined"
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  calculateTotalAfterDiscount(e.target.value);
                                }
                                }
                                disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                                InputLabelProps={{ shrink: getValues("discount") ? true : undefined }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                          <Typography>ราคาหลังหักส่วนลด</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                          <Typography >
                            {totalAfterDiscound ? totalAfterDiscound.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }) : '0.00'}
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                          <Typography fontWeight={'bold'}>ภาษีมูลค่าเพิ่ม 7%</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                          <Typography fontWeight={'bold'}>
                            {vatTotal ? vatTotal.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }) : '0.00'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <RadioGroup
                            aria-label="vatType"
                            name="vatType"
                            value={vatType}
                            onChange={(e) => {
                              setVatType(e.target.value);
                              calculateVat(e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="noneVat"
                              control={<Radio />}
                              label="ไม่รวมภาษี"
                              disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                            />
                            <FormControlLabel
                              value="includeVat"
                              control={<Radio />}
                              label="ภาษีรวมใน"
                              disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                            />
                            <FormControlLabel
                              value="excludeVat"
                              control={<Radio />}
                              label="ภาษีแยกนอก"
                              disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                            />
                          </RadioGroup>

                        </Grid>
                        <Grid item xs={12} >
                          <TextField
                            label="หมายเหตุ"
                            {...register("note")}
                            fullWidth
                            multiline
                            variant="outlined"
                            disabled={from === "create" ? false : props.receiveData?.status === "waiting" ? false : true}
                            InputLabelProps={{ shrink: getValues("note") ? true : undefined }}
                          />
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                          <Typography fontWeight={'bold'}>จำนวนเงินทั้งหมด</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                          <Typography fontWeight={'bold'}>
                            {grandTotal ? grandTotal.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }) : '0.00'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                      <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ผู้ทํารายการ: </b>
                          {props.receiveData?.createBy?.firstName} {props.receiveData?.createBy?.lastName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ผู้อนุมัติ/ปฏิเสธ: </b>
                          {props.receiveData?.approveBy?.firstName} {props.receiveData?.approveBy?.lastName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Grid container spacing={2} mt={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} justifyContent={"end"}>
                        <Stack spacing={2} direction="row">
                          {
                            isFromStatus ? (
                              <>
                                {
                                  userGroup?.canApprove && props.receiveData?.status === "waiting" ?
                                    <>
                                      <Button
                                        variant="contained"
                                        fullWidth
                                        color="error"
                                        onClick={onReject}
                                      >
                                        ปฏิเสธ
                                      </Button>
                                      <Button
                                        variant="contained"
                                        color="success"
                                        fullWidth
                                        onClick={onApprove}
                                        style={{ marginLeft: '8px' }}
                                      >
                                        อนุมัติ
                                      </Button></> :
                                    null
                                }
                              </>
                            ) :
                              <>
                                {
                                  props.receiveData?.status === "waiting" || from === "create" ? <>  <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => navigate("/inventory-receive")}
                                  >
                                    ยกเลิก
                                  </Button>
                                    {props.permission.create || (props.permission.edit && props.receiveId) ? (
                                      <Button
                                        id="submit"
                                        variant="contained"
                                        onClick={() => onSubmit("create")}
                                      >
                                        บันทึก
                                      </Button>
                                    ) : null}
                                  </> :
                                    null
                                }

                              </>
                          }
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <MaterialsCreateView open={openCreateDailog?.open && openCreateDailog?.type === "material"} onClose={() => {
        setOpenCreateDailog({ ...openCreateDailog, open: false });
        setName("");
      }} onCreate={(data) => { props?.onCreateDialog(data, "material"); }} name={name} />
      <ImagePreview
        open={openImage}
        imageSrc={selectedImage}
        onClose={() => setOpenImage(false)}
      />
      <DialogCreate datas={openCreateDailog?.datas}
        open={openCreateDailog?.open && openCreateDailog?.type !== "material"} title={openCreateDailog?.title}
        type={openCreateDailog?.type} onClose={() => {
          setName("");
          setOpenCreateDailog({ ...openCreateDailog, open: false });
        }}
        onSubmit={(data, type) => {
          props?.onCreateDialog(data, type);
          setName("");
        }} onFetchData={(id, type) => {
          props?.onFetchData(id, type);
        }}
        onCreateDialog={(data, type) => { props?.onCreateDialog(data, type); }} />
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          setName("");
        }}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            onDelete();
          } else {
            handleSubmit(onFormSubmit)();
          }
        }}
      />


    </Box >
  );
};

export default ReceiveCreateView;

