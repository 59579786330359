import { Box, Breadcrumbs, ButtonProps, Link, Grid, IconButton, Typography, Button, Card, CardContent, FormControl, TextField, Divider, Stack, InputLabel, MenuItem, Select } from "@mui/material";
import { IUserLogined } from "../../Login/Services/LoginService";
import { IInvoice } from "../Services/InvoiceService";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DialogConfirm from "../../../components/DialogConfirm";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { statusOrderOptions } from "../../../constants/statusOrderOptions";

interface InvoiceCreateViewProps {
  invoice: IInvoice;
  onCreate: (data: IInvoice) => void;
  onUpdate: (data: IInvoice) => void;
  user?: IUserLogined;
  onDelete: () => void;
  permission?: IUserDetail;
}


const InvoiceCreateView: FC<InvoiceCreateViewProps> = (props) => {

  const { register, handleSubmit, reset, getValues, formState: { errors }, control, watch } = useForm<IInvoice>({
    defaultValues: {
      dateFrom: dayjs(new Date()).format('YYYY-MM-DD'),
      dateTo: dayjs(new Date()).format('YYYY-MM-DD'),
    }
  });

  const navigate = useNavigate();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });

  watch();

  const handleSave = handleSubmit(() => {
    if (props.invoice.id) {
      setOpenConfirm({
        open: true,
        title: "แก้ไขข้อมูล",
        message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "update",
      });
    } else {
      setOpenConfirm({
        open: true,
        title: "บันทึกข้อมูล",
        message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "create",
      });
    }
  });

  const onSubmit = () => {
    const value = getValues();
    if (props.invoice.id) {
      props.onUpdate(value);
    } else {
      props.onCreate(value);
    }
  };

  const handleDelete = () => {
    setOpenConfirm({
      open: true,
      title: "ลบข้อมูล",
      message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
      color: "error",
      type: "delete",
    });
  };

  const onDelete = () => {
    props.onDelete();
  };

  useEffect(() => {
    if (props.invoice.id) {
      reset(props.invoice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.invoice]);

  return (
    <Box component="div" sx={{ width: '100%' }}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="back"
            onClick={() => navigate("/process-invoice-list")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ใบคำสั่งซื้อ</Typography>
            <Link component="button" onClick={() => navigate("/process-invoice-list")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.invoice.id ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {props.invoice.id && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => handleDelete()}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Typography variant="h6">ใบคำสั่งซื้อ</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                      <Controller
                        name="dateFrom"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            slotProps={{
                              textField: {
                                variant: 'outlined',
                                error: !!errors.dateFrom,
                                helperText: errors.dateFrom ? "กรุณาระบุวันที่เริ่มต้น" : "",
                              }
                            }}
                            label="วันที่เริ่มต้น"
                            format="DD/MM/YYYY"
                            {...field}
                            defaultValue={dayjs(new Date())}
                            value={field.value ? dayjs(field.value) : dayjs(new Date())}
                            onChange={(date) => {
                              field.onChange(date ? date.format('YYYY-MM-DD') : null);
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                      <Controller
                        name="dateTo"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            slotProps={{
                              textField: {
                                variant: 'outlined',
                                error: !!errors.dateTo,
                                helperText: errors.dateTo ? "กรุณาระบุวันที่สิ้นสุด" : "",
                              }
                            }}
                            label="วันที่สิ้นสุด"
                            format="DD/MM/YYYY"
                            {...field}
                            defaultValue={dayjs(new Date())}
                            value={field.value ? dayjs(field.value) : dayjs(new Date())}
                            onChange={(date) => {
                              field.onChange(date ? date.format('YYYY-MM-DD') : null);
                            }}

                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    fullWidth
                    label="Invoice No"
                    {...register("invoiceNo", { required: true })}
                    error={!!errors.invoiceNo}
                    helperText={errors.invoiceNo ? "กรุณาระบุเลข Invoice" : ""}
                    InputLabelProps={{ shrink: getValues("invoiceNo") ? true : undefined }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Controller
                    name="isComplete"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id="status-select-label">สถานะ</InputLabel>
                        <Select
                          labelId="status-select-label"
                          id="status-select"
                          value={field.value?.toString() || "false"}
                          label="สถานะ"
                          onChange={(event) => {
                            field.onChange(event.target.value === "true");
                          }}
                        >
                          {statusOrderOptions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={9} md={9} lg={9}>
                  <TextField
                    fullWidth
                    label="รายละเอียด"
                    multiline
                    rows={2}
                    {...register("remark")}
                    InputLabelProps={{ shrink: getValues("remark") ? true : undefined }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <TextField
                    fullWidth
                    label="ราคา"
                    type="number"
                    {...register("price")}
                    InputLabelProps={{ shrink: getValues("price") ? true : undefined }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} mt={1}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} justifyContent={"end"}>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => navigate("/process-invoice-list")}
                    >
                      ยกเลิก
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleSave}
                    >
                      บันทึก
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            onDelete();
          } else {
            onSubmit();
          }
        }}
      />
    </Box>
  );
};

export default InvoiceCreateView;