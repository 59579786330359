import { FC } from "react";
import { ICostReport, ICostReports, IReportCriteria } from "../Services/ReportService";
import ReportListView from "../../../components/ReportListView";
import dayjs from "dayjs";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";

interface CostReportListViewProps {
  isLoading: boolean;
  costReportData: ICostReports;
  getCostReportData: (criteria: IReportCriteria) => void;
}
const CostReportListView: FC<CostReportListViewProps> = (props) => {
  const navigate = useNavigate();
  const costReportColumns = [
    { label: 'วันที่', render: (row: ICostReport) => row.orderDate ? dayjs(row.orderDate).format("DD/MM/YYYY HH:mm") : "" },
    { label: 'Invoice No.', render: (row: ICostReport) => row.invoiceNo ?? "" },
    { label: 'ชื่อโปรเจกต์', render: (row: ICostReport) => row.projectName ?? "" },
    {
      label: 'มูลค่าทั้งหมด', render: (row: ICostReport) => (
        <strong>{row.cost ? thaiFormatNumber(row.cost.toString()) : "0.00"}</strong>
      )
    },

  ];

  const totalValues = [
    { label: "ต้นทุน", value: props.costReportData?.totalValue?.totalCost, percentage: props.costReportData?.totalValue?.costPercentChange },
    { label: "ยอดขาย", value: props.costReportData?.totalValue?.totalSales, percentage: props.costReportData?.totalValue?.salesPercentChange },
    { label: "ยอดสูญเสีย", value: props.costReportData?.totalValue?.totalLoss, percentage: props.costReportData?.totalValue?.lossPercentChange },
    { label: "รายได้อื่นๆ", value: props.costReportData?.totalValue?.otherIncome },
    { label: "กำไร", value: props.costReportData?.totalValue?.totalProfit, percentage: props.costReportData?.totalValue?.profitPercentChange },
  ];

  const rowActions = (row: ICostReport) => (
    <IconButton
      onClick={() => {
        navigate(`/process-order-edit/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  return (
    <ReportListView
      isLoading={props.isLoading}
      reportData={props.costReportData}
      getReportData={props.getCostReportData}
      reportTitle="รายงานต้นทุน"
      totalValues={totalValues}
      reportColumns={costReportColumns}
      rowActions={rowActions}
    />
  );
};

export default CostReportListView;
