import React, { FC, useState } from "react";
import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Typography } from "@mui/material";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, ResponsiveContainer } from "recharts";
import TotalValuesCard from "../../../components/TotalValuesCard";
import { IDashboard, IDashboardCriteria } from "../Services/DashboardService";
import { IAllTotalValue } from "../../Report/Services/ReportService";
import { thaiNameMonth } from "../../../constants/thaiNameMonth";
import SimpleBackdrop from "../../../components/SimpleBackdrop";

interface DashboardViewProps {
  dashboardData: IDashboard | null;
  getDashboardData: (criteria: IDashboardCriteria) => void;
  totalValueData: IAllTotalValue | null;
  getTotalValue: (criteria: IDashboardCriteria) => void;
  isLoading: boolean;
}

const DashboardView: FC<DashboardViewProps> = (props) => {
  const totalValues = [
    { label: "ต้นทุน", value: props.totalValueData?.totalCost ?? 0, percentage: props.totalValueData?.costPercentChange },
    { label: "ยอดขาย", value: props.totalValueData?.totalSales ?? 0, percentage: props.totalValueData?.salesPercentChange },
    { label: "ยอดสูญเสีย", value: props.totalValueData?.totalLoss ?? 0, percentage: props.totalValueData?.lossPercentChange },
    { label: "รายได้อื่นๆ", value: props.totalValueData?.otherIncome ?? 0 },
    { label: "กำไร", value: props.totalValueData?.totalProfit ?? 0, percentage: props.totalValueData?.profitPercentChange },
  ];

  const [selectedMonth, setSelectedMonth] = useState<number | 'all'>((new Date()).getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState<number>((new Date()).getFullYear());
  const years = Array.from(new Array(11), (_, index) => 2024 + index);



  const handleMonthChange = (event: SelectChangeEvent<number | 'all'>) => {
    const month = event.target.value === 'all' ? 'all' : event.target.value as number;
    setSelectedMonth(month);
    props.getTotalValue({ month, year: selectedYear });
  };

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    const year = event.target.value as number;
    setSelectedYear(year);
    props.getDashboardData({ month: selectedMonth, year });
    props.getTotalValue({ month: selectedMonth, year });
  };

  const colors = {
    cost: "#aa83f7",
    sales: "#f9bf68",
    profit: "#5ecec4",
    loss: "#f38e70",
  };

  if (!props.dashboardData) return null;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <Grid item xs={12}>
              <Typography variant="h5">ภาพรวม</Typography>
            </Grid>
            <>
              <FormControl sx={{ minWidth: 120, marginRight: '10px' }}>
                <InputLabel id="select-month-label">เดือน</InputLabel>
                <Select labelId="select-month-label" id="select-month" label="เดือน"
                  value={selectedMonth} onChange={handleMonthChange}>
                  <MenuItem value="all">ทั้งหมด</MenuItem>
                  {Array.from({ length: 12 }, (_, index) => index + 1).map(month => (
                    <MenuItem key={month} value={month}>{thaiNameMonth[month - 1]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="select-year-label">ปี</InputLabel>
                <Select labelId="select-year-label" id="select-year" label="ปี"
                  value={selectedYear} onChange={handleYearChange}>
                  {years.map(year => (
                    <MenuItem key={year} value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          </Box>
        </Grid>

        {totalValues.map((total, index) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
            <TotalValuesCard total={total} index={index} />
          </Grid>
        ))}

        <Grid item xs={12}>
          <Paper sx={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h6" mb={2}>ภาพรวม</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ResponsiveContainer width="100%" height={300} debounce={200} >
                <BarChart data={props.dashboardData?.datas} >
                  <CartesianGrid strokeDasharray="4 4" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend align='center' />
                  <Bar dataKey="cost" fill={colors.cost} name="ต้นทุน" />
                  <Bar dataKey="sales" fill={colors.sales} name="ยอดขาย" />
                  <Bar dataKey="profit" fill={colors.profit} name="กำไร" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Cost Charts */}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Paper sx={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h6" mb={2}>ต้นทุน</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ResponsiveContainer width="100%" height={300} debounce={200}>
                <BarChart data={props.dashboardData?.datas} maxBarSize={30}>
                  <CartesianGrid strokeDasharray="4 4" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend align='center' />
                  <Bar dataKey="cost" fill={colors.cost} name="ต้นทุน" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Sales Charts */}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Paper sx={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h6" mb={2}>ยอดขาย</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ResponsiveContainer width="100%" height={300} debounce={200}>
                <BarChart data={props.dashboardData?.datas} maxBarSize={30}>
                  <CartesianGrid strokeDasharray="4 4" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend align='center' />
                  <Bar dataKey="sales" fill={colors.sales} name="ยอดขาย" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Profits Charts */}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Paper sx={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h6" mb={2}>กำไร</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ResponsiveContainer width="100%" height={300} debounce={200}>
                <BarChart data={props.dashboardData?.datas} maxBarSize={30}>
                  <CartesianGrid strokeDasharray="4 4" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend align='center' />
                  <Bar dataKey="profit" fill={colors.profit} name="กำไร" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Loss Charts */}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Paper sx={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h6" mb={2}>ยอดการสูญเสีย</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ResponsiveContainer width="100%" height={300} debounce={200}>
                <BarChart data={props.dashboardData?.datas} maxBarSize={30}>
                  <CartesianGrid strokeDasharray="4 4" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend align='center' />
                  <Bar dataKey="loss" fill={colors.loss} name="ยอดการสูญเสีย" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <SimpleBackdrop open={props.isLoading ?? false} />
    </Box>
  );
};

export default DashboardView;
