import { FC, useEffect, useState } from "react";
import { IFarmExpenseDetail, IFarmProductReceipt, IFarmReceiptBasket, ITransportRefference } from "../Services/FarmProductReceipt";
import { IUploadFile } from "../../Company/Services/CompanyService";
import { Box, Breadcrumbs, Grid, IconButton, Typography, Link, CardContent, Card, Button, Autocomplete, TextField, FormControl, Divider, styled, Stack, ButtonProps, FormControlLabel, Radio, RadioGroup, useMediaQuery, createFilterOptions } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IUserDetail, IUserSuperAdmin } from "../../SuperAdminManagement/Services/UserService";
import DeleteIcon from "@mui/icons-material/Delete";
import { IOrder } from "../Services/OrderService";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DialogConfirm from "../../../components/DialogConfirm";
import ImagePreview from "../../../components/ImagePreview";
import { ISupplier } from "../../Supplier/Services/SupplierService";
import { IDistrict, IProduct, IProvince, ISubDistrict } from "../Services/ProductService";
import { IVapourHeatTreatment } from "../Services/VapourHeatTreatmentService";
import { IGrade } from "../Services/GradeService";
import { IDriver } from "../../Transport/Services/DriverService";
import { ICar } from "../../Transport/Services/CarService";
import { IExpenseType } from "../../Expense/Services/ExpenseTypeService";
import { IUserLogined } from "../../Login/Services/LoginService";
import { theme } from "../../../themes";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { v4 as uuidv4 } from "uuid";
import { ISupplierType } from "../../Supplier/Services/SupplierTypeService";
import DialogCreate from "../../../components/DialogCreate";
import { supplierDatas, vapourHeatTreatmentDatas } from "../../../constants/dialogCreateInputs";
import CarCreateView from "../../Transport/Views/CarCreateView";
import DriverCreateView from "../../Transport/Views/DriverCreateView";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";
import ExpenseTypeCreateView from "../../Expense/Views/ExpenseTypeCreateView";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import DialogCreateBasketDetails from "../../../components/DialogCreateBasketDetails";
import { useFileManagement } from "../../../utils/fileHandlers";
import { IInvoice } from "../Services/InvoiceService";
import SimpleBackdrop from "../../../components/SimpleBackdrop";

interface FarmProductReceiptCreateViewProps {
  orders: IOrder[];
  farmProductReceipt: IFarmProductReceipt;
  permission: IUserDetail;
  suppliers: ISupplier[];
  grades: IGrade[];
  drivers: IDriver[];
  cars: ICar[];
  expenseTypes: IExpenseType[];
  invoices: IInvoice[];
  vapourHeatTreatmentData: IVapourHeatTreatment[];
  onCreate: (data: IFarmProductReceipt, fileUpload: IUploadFile) => void;
  onUpdate: (data: IFarmProductReceipt, oldImage: IUploadFile[], fileUpload: IUploadFile[], deleteFile: IUploadFile[]) => void;
  onRemove: (id: string) => void;
  isLoading: boolean;
  user: IUserLogined;
  users: IUserSuperAdmin[];
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onCreateDialog: (data: any, type: string) => void;
  onFetchData: (id?: any, type?: string) => void;
  supplierTypesOptions?: ISupplierType[];
  // ประเภทค่าใช้จ่าย
  onCreateExpenseType: (data: IExpenseType) => void;
  onGetOrders: (invoiceNo?: string) => void;
}
const filterOptions = createFilterOptions<any>();

const defaultBasketDetails = { grade: { id: '', name: '' } as IGrade, emptyBasketWeight: 0, totalBasketWeight: 0, details: [] as IFarmReceiptBasket[], basketAmount: 0, totalWeight: 0, netWeight: 0, pricePerKg: 0, paidPrice: 0, paidPriceActual: 0, weightPerBasket: 0, totalBasketExtra: 0, totalWeightExtra: 0, netWeightFarm: 0, };
const FarmProductReceiptCreateView: FC<FarmProductReceiptCreateViewProps> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const userGroup = props.user?.userGroup;
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: null };
  const isFromStatus = from === "status" || from === "order";
  const isDisabled = isFromStatus ? true : false;
  const [openExpenseType, setOpenExpenseType] = useState(false);
  const [name, setName] = useState("");
  const [basketDetailsList, setBasketDetailsList] = useState([{ ...defaultBasketDetails, id: uuidv4() }]);
  const [basketDetails, setBasketDetails] = useState<IFarmReceiptBasket[]>();
  const [openBasketDetails, setOpenBasketDetails] = useState(false);
  const [basketIndex, setBasketIndex] = useState<number>(0);
  const [totalWeightFromFarm, setTotalWeightFromFarm] = useState(0);

  const { handleSubmit, setValue, reset, getValues, watch, formState: { errors }, control } = useForm<IFarmProductReceipt>({
    defaultValues: {
      docDate: dayjs().format('YYYY-MM-DD'),
      depositDate: new Date(),
      deposit: 0,
      emptyBasketWeight: 0,
      remainPaymentDate: new Date(),
      farmExpenseDetail: [{
        id: uuidv4(),
        type: 1,
        amount: 0,
        payDate: new Date(),
        payBy: props.user
      }]
    }
  });

  const watchData = watch('farmExpenseDetail');
  watch();

  const { remove, fields, append } = useFieldArray({
    control,
    name: "farmExpenseDetail"
  });

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });
  const isDisable = isFromStatus ? true : false;

  const [productList, setProductList] = useState<IProduct[]>();
  const [transportRefList, setTransportRefList] = useState<ITransportRefference[]>();
  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isReject, setIsReject] = useState(false);
  const [totalBasketWeight, setTotalBasketWeight] = useState(0);
  const order = watch('order');
  const [openCreateDailog, setOpenCreateDailog] = useState({ open: false, datas: [], type: "", title: "" });
  const [nameType, setNameType] = useState("");
  const {
    selectedFiles,
    images,
    pdfFiles,
    deleteImages,
    setImages,
    setPdfFiles,
    setSelectedFiles,
    setDeleteImages,
    handleFileChange,
    handleFileRemove,
    handleFileRemoveImageList,
    handleFileRemovePdfList,
  } = useFileManagement();

  // NOTE: supplier input
  const datasInChildCreateDialog = [{ type: "text", label: "ประเภท", name: "name", xs: 12, sm: 12, md: 12, lg: 12, defaultValue: "" },];
  const _supplierDatas: any = supplierDatas({
    provinceData: props?.provinceData || [],
    districtData: props?.districtData || [],
    subDistrictData: props?.subDistrictData || [],
    datasInChildCreateDialog: datasInChildCreateDialog || [],
    supplierTypesOptions: props?.supplierTypesOptions || [],
    name: name
  }) || [];

  const _vapourHeatTreatmentDatas: any = vapourHeatTreatmentDatas({
    provinceData: props?.provinceData || [],
    districtData: props?.districtData || [],
    subDistrictData: props?.subDistrictData || [],
    name: name
  }) || [];

  const setData = (data: IFarmProductReceipt) => {
    if (data) {
      if (data.order && data.order.product) {
        if (Array.isArray(data.order.product)) {
          setProductList(data.order.product);
        } else {
          setProductList([data.order.product]);
        }
      }
      if (data.farmReceiptBasket && data.farmReceiptBasket.length > 0) {
        const sortByGrade = data.farmReceiptBasket.sort((a, b) => (a.grade?.name ?? "").localeCompare(b.grade?.name ?? ""));
        const checkGroupGrade = sortByGrade.reduce<IFarmReceiptBasket[]>((acc, cur) => {
          const index = acc.findIndex((item) => item.grade?.id === cur.grade?.id);
          if (index > -1) {
            if (acc[index]) {
              acc[index].weight = (acc[index].weight ?? 0);
              acc[index].weightPerBasket = (acc[index].weightPerBasket ?? 0);
              acc[index].emptyBasketWeight = (acc[index].emptyBasketWeight ?? 0);
              acc[index].totalBasketExtra = (acc[index].totalBasketExtra ?? 0);
              acc[index].totalWeightExtra = (acc[index].totalWeightExtra ?? 0);

            }
          } else {
            acc.push(cur);
          }
          return acc;
        }, []);
        if (checkGroupGrade && checkGroupGrade.length > 0) {
          let datas = [] as any[];
          checkGroupGrade.forEach((item) => {
            const filterDetailsByGrade = sortByGrade.filter((detail) => detail.grade?.id === item.grade?.id);
            const details = filterDetailsByGrade;
            const basketAmount = filterDetailsByGrade.length;

            const totalWeight = filterDetailsByGrade.reduce((acc, cur) => Number(acc ?? 0) + Number(cur.weight ?? 0), 0);

            const emptyBasketWeight = filterDetailsByGrade[0].emptyBasketWeight ?? 3;
            const pricePerKg = filterDetailsByGrade[0].pricePerKg ?? 0;
            const paidPrice = filterDetailsByGrade[0].paidPrice ?? 0;
            const paidPriceActual = filterDetailsByGrade[0].paidPriceActual ?? 0;

            const totalBasketWeight = (filterDetailsByGrade.filter((detailGrade) => detailGrade.weight && detailGrade.weightPerBasket && detailGrade.weight >= detailGrade.weightPerBasket).length || 0) * emptyBasketWeight;



            const totalBasketExtra = filterDetailsByGrade[0].totalBasketExtra ?? 0;
            const totalWeightExtra = filterDetailsByGrade[0].totalWeightExtra ?? 0;
            const netWeight = totalWeight - totalBasketWeight;

            const netWeightFarm = Number((filterDetailsByGrade.filter((detailGrade) => detailGrade.weight && detailGrade.weightPerBasket && detailGrade.weight >= detailGrade.weightPerBasket).length || 0) * (filterDetailsByGrade[0].weightPerBasket || 0)) + Number(filterDetailsByGrade[0].totalWeightExtra);

            datas.push({ grade: item.grade, emptyBasketWeight: emptyBasketWeight, details: details, basketAmount: basketAmount, totalWeight: totalWeight, totalBasketWeight: totalBasketWeight, netWeight: netWeight, pricePerKg: pricePerKg, paidPrice: paidPrice, weightPerBasket: item.weightPerBasket, paidPriceActual: paidPriceActual, totalBasketExtra: totalBasketExtra, totalWeightExtra: totalWeightExtra, netWeightFarm: netWeightFarm });
          });
          setBasketDetailsList(datas);
        }
      }
      reset(data);
      calculateTotalWeightFromFarm();
      // handlePricePaidToFarm();
    }

  };

  const calNetTotalWeight = () => {
    const totalEmplyBasketWeight = basketDetailsList.reduce((acc, cur) => acc + cur.totalBasketWeight, 0 as number);
    setTotalBasketWeight(totalEmplyBasketWeight);
    setValue('netWeight', totalWeightFromFarm);
  };

  const handleSave = handleSubmit(() => {
    if (props.farmProductReceipt.id) {
      setOpenConfirm({
        open: true,
        title: "แก้ไขข้อมูล",
        message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "update",
      });
    } else {
      setOpenConfirm({
        open: true,
        title: "บันทึกข้อมูล",
        message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "create",
      });
    }
  });

  const handleDelete = () => {
    setOpenConfirm({
      open: true,
      title: "ลบข้อมูล",
      message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
      color: "error",
      type: "delete",
    });
  };

  const onDelete = () => {
    if (props.farmProductReceipt.id) {
      props.onRemove(props.farmProductReceipt.id);
    }
  };

  const onSubmit = () => {
    const value = getValues();
    if (basketDetailsList && basketDetailsList.length > 0) {
      let _basketDetails = [] as IFarmReceiptBasket[];
      basketDetailsList.forEach((basket) => {
        if (basket.details && basket.details.length > 0) {
          basket.details.forEach((detail) => {
            detail.paidPriceActual = basket.paidPriceActual || 0;
            detail.paidPrice = basket.paidPrice || 0;
            detail.pricePerKg = basket.pricePerKg || 0;
            detail.weightPerBasket = basket.weightPerBasket || 0;
            detail.totalBasketExtra = basket.totalBasketExtra || 0;
            detail.totalWeightExtra = basket.totalWeightExtra || 0;
          });
          _basketDetails = [..._basketDetails, ...basket.details];
        }
      });
      value.farmReceiptBasket = _basketDetails;
    } else {
      value.farmReceiptBasket = [];
    }
    value.totalWeightFromFarm = totalWeightFromFarm;
    if (props.farmProductReceipt.id) {
      props.onUpdate({
        ...value,
        preWeighingWeight: value.preWeighingWeight || 0,
        weightPerBasketFromFarm: value.weightPerBasketFromFarm || 0, status: isFromStatus ? isReject ? "rejected" : "approved" : "waiting"
      }, images, selectedFiles, deleteImages);
    }
    else {
      props.onCreate(value, selectedFiles);
    }
    setIsReject(false);
    setSelectedFiles([]);
    setDeleteImages([]);
  };

  const calGrandTotal = () => {
    const deposit = getValues('deposit') || 0;
    const totalRemain = getValues('totalRemain') || 0;
    setValue('grandTotal', (Number(deposit) + Number(totalRemain)));
  };

  const calTotalRemain = () => {
    const priceActualPaidToFarm = getValues('priceActualPaidToFarm') || 0;
    const deposit = getValues('deposit') || 0;
    setValue('totalRemain', (Number(priceActualPaidToFarm) - Number(deposit)));
    calGrandTotal();
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const onImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setOpenImage(true);
  };

  const onApprove = () => {
    setOpenConfirm({
      open: true,
      title: "อนุมัติเอกสาร",
      message: "คุณต้องการอนุมัติข้อมูลรับจากสวนนี้ใช่หรือไม่",
      color: "primary",
      type: "update",
    });
    setIsReject(false);
  };

  const onReject = () => {
    setOpenConfirm({
      open: true,
      title: "ปฏิเสธเอกสาร",
      message: "คุณต้องการปฏิเสธข้อมูลรับจากสวนนี้ใช่หรือไม่",
      color: "error",
      type: "update",
    });
    setIsReject(true);
  };

  const handleTransportRef = (data: IFarmProductReceipt[]) => {
    if (props.farmProductReceipt.id) {
      const transportRef = data.filter((item) => item.id !== props.farmProductReceipt.id);
      setTransportRefList(transportRef);
    } else {
      setTransportRefList(data);
    }
  };

  const handleOpenCreateDailog = (name: string) => {
    setNameType(name);
    switch (name) {
      case "agent": setOpenCreateDailog(
        {
          open: true, datas: _supplierDatas, type: "agent", title: "สร้าง Supplier"
        }
      );
        break;
      case "supplierType": setOpenCreateDailog(
        {
          open: true, datas: [], type: "supplierType", title: "สร้างประเภท Supplier"
        }
      );
        break;
      case "vapourHeatTreatment": setOpenCreateDailog(
        {
          open: true, datas: _vapourHeatTreatmentDatas, type: "vapourHeatTreatment", title: "สร้างโรงอบ"
        }
      );
        break;
      case "car": setOpenCreateDailog(
        {
          open: true, datas: [], type: "car", title: "สร้างข้อมูลรถ"
        }
      );
        break;
      case "driver": setOpenCreateDailog(
        {
          open: true, datas: [], type: "driver", title: "สร้างคนขับ"
        }
      );
        break;
    }
  };

  const removeFarmReceiptBasket = (index?: number) => {
    const basket = [...basketDetailsList];
    if (index !== undefined) {

      const _data = basketDetailsList[index];
      const currentTotalWeight = getValues('totalWeight') || 0;
      const currentBasketWeight = getValues('emptyBasketWeight') || 0;
      const newTotalWeight = currentTotalWeight - Number(_data.totalWeight);
      const newTotalBasketWeight = currentBasketWeight - Number(_data.totalBasketWeight);

      const totalBasketValue = Number(basket.reduce((acc, cur) => acc + cur.basketAmount, 0 as number)) - Number(_data.basketAmount);
      setValue('basketAmount', totalBasketValue);

      setValue('totalWeight', newTotalWeight);
      setValue('emptyBasketWeight', newTotalBasketWeight);
      const filter = basket.filter((item, i) => i !== index);
      setBasketDetailsList(filter);
      const _totalWeight = filter.reduce((acc, cur) => {

        const baskets = Number(cur.details.length || 0) - Number(cur.totalBasketExtra || 0);
        const normalTotalWeight = Number(baskets * (cur.weightPerBasket || 0));
        acc += Number(normalTotalWeight) + Number(cur.totalWeightExtra || 0);
        return acc;
      }, 0 as number);
      setTotalWeightFromFarm(_totalWeight > 0 ? _totalWeight : 0);

      handlePricePaidToFarm(filter);
      calNetWeightFarm(filter);
    } else {
      basket.pop();
      const _totalWeight = basket.reduce((acc, cur) => {

        const baskets = Number(cur.details.length || 0) - Number(cur.totalBasketExtra || 0);
        const normalTotalWeight = Number(baskets * (cur.weightPerBasket || 0));
        acc += Number(normalTotalWeight) + Number(cur.totalWeightExtra || 0);
        return acc;
      }, 0 as number);
      setTotalWeightFromFarm(_totalWeight > 0 ? _totalWeight : 0);

      handlePricePaidToFarm(basket);
      calNetWeightFarm(basket);
      setBasketDetailsList(basket);
    }
    calGrandTotal();
  };

  const addFarmReceiptBasket = () => {
    setBasketDetailsList([...basketDetailsList, { ...defaultBasketDetails, id: uuidv4() }]);
  };

  const openHandleBasketDetails = (index: number) => {
    setBasketIndex(index);
    const data = basketDetailsList[index];
    let basketDetails = [] as IFarmReceiptBasket[];
    if (data.basketAmount) {
      for (let i = 0; i < data.basketAmount; i++) {
        basketDetails.push({ grade: data.grade, weight: data.details[i]?.weight ?? 0, emptyBasketWeight: data.emptyBasketWeight, weightPerBasket: data.weightPerBasket });
      }
    }
    setBasketDetails(basketDetails);
    setOpenBasketDetails(true);
  };

  const handleBasketDetails = (data: IFarmReceiptBasket[], totalWeight: number, index: number, totalWeightExtra: number, totalBasketExtra: number) => {
    setOpenBasketDetails(false);
    const basket = [...basketDetailsList];
    basket[index].details = data;
    basket[index].totalWeight = totalWeight;
    basket[index].totalBasketWeight = (data?.length - totalBasketExtra) * basket[index].emptyBasketWeight;

    basket[index].netWeight = totalWeight - basket[index].totalBasketWeight;
    basket[index].totalWeightExtra = totalWeightExtra;
    basket[index].totalBasketExtra = totalBasketExtra;
    basket[index].netWeightFarm = Number((Number(data?.length) - Number(totalBasketExtra)) * basket[index].weightPerBasket) + Number(totalWeightExtra);

    setBasketDetailsList(basket);
    setOpenBasketDetails(false);

    const totalBasketValue = Number(basket.reduce((acc, cur) => acc + cur.basketAmount, 0 as number)) - Number(totalBasketExtra);
    setValue('basketAmount', totalBasketValue);
    const totalWeightValue = basketDetailsList.reduce((acc, cur) => acc + cur.totalWeight, 0 as number);

    setValue('totalWeight', totalWeightValue);

    calNetTotalWeight();
    calculateTotalWeightFromFarm();
    calPaidPrice(index);
    calNetWeightFarm();
  };


  const calNetWeightFarm = (_basketDetailsList?: any) => {

    const _basketDetailsLists = _basketDetailsList ? _basketDetailsList : basketDetailsList;

    const preWeighingWeight = getValues('preWeighingWeight');
    const netWeightFarm = _basketDetailsLists?.reduce((acc: any, cur: any) => {
      return acc + cur.netWeight;
    }, 0 as number) - Number(preWeighingWeight || 0);


    setValue('netWeightFarm', netWeightFarm);
    calculateTotalDiffWeight();
    return netWeightFarm || 0;
  };

  const calculateTotalDiffWeight = () => {
    const netWeightFromFarm = getValues('totalWeightFromFarm') || 0;
    const netWeightFarm = getValues('netWeightFarm') || 0;
    const totalDiffWeight = netWeightFarm - (netWeightFromFarm ?? 0);
    setValue('totalDiffWeight', totalDiffWeight);
  };

  const calculateTotalWeightFromFarm = () => {
    const _totalWeight = basketDetailsList.reduce((acc, cur) => {

      const baskets = Number(cur.details.length || 0) - Number(cur.totalBasketExtra || 0);
      const normalTotalWeight = Number(baskets * (cur.weightPerBasket || 0));
      acc += Number(normalTotalWeight) + Number(cur.totalWeightExtra || 0);
      return acc;
    }, 0 as number);
    setValue('totalWeightFromFarm', _totalWeight > 0 ? _totalWeight : 0);
    setTotalWeightFromFarm(_totalWeight > 0 ? _totalWeight : 0);
    calculateTotalDiffWeight();
  };

  const handleEmptyBasketWeight = (index: number) => {
    const basketWeight = basketDetailsList.reduce((acc, cur) => {
      if (cur.basketAmount >= cur.weightPerBasket) {
        return acc + cur.emptyBasketWeight;
      } else {
        return 0;
      }
    }, 0 as number);

    setValue('emptyBasketWeight', basketWeight);
    calNetTotalWeight();

    handleTotalNetWeight(index);
    handlePricePaidToFarm();
  };

  const handleBasketAmount = (index: number) => {
    const basketAmount = basketDetailsList.reduce((acc, cur) => {
      if (cur.basketAmount >= cur.weightPerBasket) {
        return acc + (cur.basketAmount - cur.totalBasketExtra);
      } else {
        return 0;
      }
    }, 0 as number);
    setValue('basketAmount', basketAmount);
    handleEmptyBasketWeight(index);
    calculateTotalWeightFromFarm();
    handleTotalNetWeight(index);
  };

  const handleTotalNetWeight = (index: number) => {
    const currentIndex = basketDetailsList[index];
    const totalWeight = currentIndex.totalWeight;
    const totalBasketWeight = currentIndex.totalBasketWeight;
    const netWeight = totalWeight - totalBasketWeight;
    const netWeightFarm = Number((Number(currentIndex.details.length) - Number(currentIndex.totalBasketExtra)) * currentIndex.weightPerBasket) + Number(currentIndex.totalWeightExtra);
    basketDetailsList[index].netWeight = netWeight;
    basketDetailsList[index].netWeightFarm = netWeightFarm;
    setBasketDetailsList([...basketDetailsList]);

    const _netWeightFarm = basketDetailsList.reduce((acc, cur) => {
      return acc + cur.netWeight;
    }, 0 as number);
    setValue('netWeightFarm', _netWeightFarm);
    calPaidPrice(index);
    calTotalWeight();
  };

  const calTotalWeight = () => {
    const totalWeight = basketDetailsList.reduce((acc, curr) => {
      return acc + curr.netWeight;
    }, 0);
    setValue('totalWeight', totalWeight);
  };


  const calPaidPrice = (index: number) => {
    const basket = [...basketDetailsList];
    const netWeight = basket[index].netWeight ?? 1;
    const pricePerKg = basket[index].pricePerKg ?? 1;
    const paidPrice = (Number(netWeight)) * pricePerKg;

    const totalBasket = Number(basket[index].basketAmount) - Number(basket[index].totalBasketExtra);

    const paidPriceActual = (
      Number(
        Number(basket[index].weightPerBasket * totalBasket)
      )
      + Number(basket[index].totalWeightExtra))
      * basket[index].pricePerKg;

    basket[index].paidPrice = paidPrice > 0 ? paidPrice : 0;
    basket[index].paidPriceActual = paidPriceActual > 0 ? paidPriceActual : 0;
    setBasketDetailsList(basket);
    handlePricePaidToFarm();
  };

  const handleCalWeightPerBasket = (index: number) => {
    const basket = [...basketDetailsList];
    let totalWeight = 0;
    let totalWeightExtra = 0;
    let totalBasketExtra = 0;
    basket[index].details.forEach((item) => {

      totalWeight += Number(item.weight) ?? 0;
      if (item.weight && item.weight < basket[index]?.weightPerBasket) {
        totalWeightExtra += Number(item.weight) ?? 0;
        totalBasketExtra++;
      }
    });

    basket[index].totalWeight = totalWeight;
    basket[index].totalWeightExtra = totalWeightExtra;
    basket[index].totalBasketExtra = totalBasketExtra;
    basket[index].totalBasketWeight = (Number(basket[index].details.length) - Number(totalBasketExtra)) * basket[index].emptyBasketWeight;
    basket[index].netWeight = Number(totalWeight) - Number((Number(basket[index].details.length) - Number(totalBasketExtra)) * basket[index].emptyBasketWeight);

    basket[index].netWeight = Number(totalWeight) - Number((Number(basket[index].details.length) - Number(totalBasketExtra)) * basket[index].emptyBasketWeight);

    basket[index].netWeightFarm = Number((Number(basket[index].details.length) - Number(basket[index].totalBasketExtra)) * basket[index].weightPerBasket) + Number(basket[index].totalWeightExtra);
    setBasketDetailsList(basket);

    calPaidPrice(index);

    calculateTotalWeightFromFarm();
  };

  const handlePricePaidToFarm = (_basketList?: any) => {
    const basketList = _basketList ? _basketList : basketDetailsList;
    const paidPrice = basketList.reduce((acc: any, cur: any) => acc + Number(cur.paidPrice), 0 as number);
    const paidPriceActual = basketList.reduce((acc: any, cur: any) => acc + Number(cur.paidPriceActual), 0 as number);
    const totalDiffPrice = paidPrice - paidPriceActual;
    setValue('pricePaidToFarm', Number(parseFloat(paidPrice).toFixed(2)));
    setValue('priceActualPaidToFarm', Number(parseFloat(paidPriceActual).toFixed(2)));
    setValue('totalDiffPrice', totalDiffPrice);
    calTotalRemain();
  };

  useEffect(() => {
    calNetTotalWeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketDetailsList]);

  useEffect(() => {
    if (props.farmProductReceipt && props.farmProductReceipt.id) {
      setData(props.farmProductReceipt);
      setImages(props.farmProductReceipt.image || []);
      setPdfFiles(props.farmProductReceipt.file || []);
      setTotalWeightFromFarm(props.farmProductReceipt.totalWeightFromFarm || 0);
      setValue('grandTotal', props.farmProductReceipt.grandTotal || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.farmProductReceipt]);

  useEffect(() => {
    handleOpenCreateDailog(nameType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.districtData, props.subDistrictData, props.supplierTypesOptions, props.supplierTypesOptions]);

  return (
    <Box component={'div'}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="back"
            onClick={() => navigate("/process-product-receipt")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          {/* NOTE: check from order or not */}
          {from === "order" ? <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>รับจากสวน</Typography>
            <Link component="button" onClick={() => navigate(`/process-order-edit/${order?.id}`)}>
              ใบคำสั่งซื้อ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.farmProductReceipt.id ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs> :
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Typography>รับจากสวน</Typography>
              <Link component="button" onClick={() => navigate("/process-product-receipt")}>
                รายการ
              </Link>
              <Typography variant="h6" color="text.primary">
                {props.farmProductReceipt.id ? "แก้ไข" : "สร้าง"}
              </Typography>
            </Breadcrumbs>
          }
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.farmProductReceipt?.id && props.permission.delete) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                handleDelete();
              }}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                {props.farmProductReceipt?.id && <Grid item xs={12} sm={12} md={12} lg={12} mb={3}>
                  <Typography variant="h6">{props.farmProductReceipt?.docNo}</Typography>
                </Grid>}
              </Grid>
              <Box component="form" mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                        <Controller
                          name="docDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              disabled={isDisabled}
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  error: !!errors.docDate,
                                  helperText: errors.docDate ? "กรุณาระบุวันที่" : "",
                                }
                              }}
                              label="วันที่"
                              format="DD/MM/YYYY"
                              {...field}
                              defaultValue={dayjs(new Date())}
                              value={field.value ? dayjs(field.value) : dayjs(new Date())}
                              onChange={(date) => {
                                field.onChange(date ? date.format('YYYY-MM-DD') : null);
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                      name="invoiceNo"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={isDisabled}
                          value={field.value as IInvoice || null}
                          options={props.invoices ?? []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.invoiceNo || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              field.onChange(newValue);
                              props.onGetOrders(newValue.id);
                              setValue('order', undefined);
                            } else {
                              field.onChange(null);
                              setValue('order', undefined);
                              setProductList([]);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Invoice No."
                              error={!!errors.invoiceNo}
                              helperText={errors.invoiceNo ? "กรุณาเลือก Invoice" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>


                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                      name="order"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={isDisabled}
                          value={field.value as IOrder || null}
                          options={props.orders ?? []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.projectName || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              field.onChange(newValue);
                              // TODO : กลับมา handle ตอน rewrite order
                              const product = newValue.product;
                              if (product) {
                                if (Array.isArray(product)) {
                                  setProductList(product);
                                } else {
                                  setProductList([product]);
                                }
                              } else {
                                setProductList([]);
                              }
                              if (newValue.farmProductReceipts) {
                                handleTransportRef(newValue.farmProductReceipts);
                              }
                            } else {
                              field.onChange(null);
                              setProductList([]);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="ชื่อโปรเจกต์"
                              error={!!errors.order}
                              helperText={errors.order ? "กรุณาเลือกโปรเจกต์" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <Box
                      sx={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px'
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>วันที่:</span> {order?.dateFrom ? `${dayjs(order?.dateFrom).format("DD/MM/YYYY") || ""} - ${dayjs(order?.dateTo).format("DD/MM/YYYY") || ""}` : "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>ชื่อโปรเจกต์:</span> {order?.projectName || "-"}                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>สินค้า:</span> {order?.product?.name || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>รายการสินค้า:</span>
                          </Typography>
                        </Grid>
                        {
                          order?.orderDetails?.map((orderDetail, orderDetailIndex) => (
                            <>
                              <Grid item xs={12} sm={12} md={12} key={orderDetailIndex}>
                                <Typography>
                                  {`${orderDetailIndex + 1}.เกรด: ${orderDetail.grade?.name} จํานวน: ${orderDetail.quantity} กิโล`}
                                </Typography>
                              </Grid>
                            </>
                          ))
                        }
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Controller
                      name="agent"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          disabled={isDisabled}
                          value={value as ISupplier || null}
                          options={props.suppliers.filter((supplier) => supplier.isOrchard)}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(_, newValue) => {
                            if (newValue?.id === "create") {
                              handleOpenCreateDailog("agent");
                              onChange(null);
                            } else if (newValue?.id) { onChange(newValue); }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                              });
                              setName(params.inputValue);
                            }
                            return filtered;
                          }}
                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Agent"
                              error={!!errors.agent}
                              helperText={errors.agent ? "กรุณาเลือก Agent" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                      name="vapourHeatTreatment"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          disabled={isDisabled}
                          value={value as IVapourHeatTreatment || null}
                          options={props.vapourHeatTreatmentData}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(_, newValue) => {
                            if (newValue?.id === "create") {
                              handleOpenCreateDailog("vapourHeatTreatment");
                              onChange(null);
                            } else if (newValue?.id) { onChange(newValue); }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                              });
                              setName(params.inputValue);
                            }
                            return filtered;
                          }}
                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="โรงอบ"
                              error={!!errors.vapourHeatTreatment}
                              helperText={errors.vapourHeatTreatment ? "กรุณาเลือกโรงอบ" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Controller
                      name="product"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={isDisabled}
                          value={field.value as IProduct || null}
                          options={productList ?? []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {

                            field.onChange(newValue);

                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="สินค้า"
                              error={!!errors.product}
                              helperText={errors.product ? "กรุณาเลือกสินค้า" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Controller
                      name="transportRef"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={isDisabled}
                          value={field.value as ITransportRefference || null}
                          options={transportRefList ?? []}
                          getOptionLabel={(option: ITransportRefference) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            return option && option.docNo ? option.docNo : "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            field.onChange(newValue);

                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="อ้างอิงการขนส่ง"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h6">รายการ</Typography>
                  </Grid>
                  {
                    basketDetailsList && basketDetailsList.map((basket, index) => (
                      <Grid item xs={12} sm={12} md={12} lg={12} key={basket.id}>
                        <Grid container spacing={3}>
                          <Grid item xs={1} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                            <IconButton
                              disabled={isDisable}
                              color="error"
                              onClick={() => {
                                removeFarmReceiptBasket(index);
                              }}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={12} sm={9.5} md={9.5} lg={9.5}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <Autocomplete
                                  value={basket.grade ?? { id: '', name: '' } as IGrade}
                                  disabled={isDisable}
                                  options={order?.orderDetails?.map((orderDetail) => orderDetail.grade) ?? []}
                                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                  getOptionLabel={(option: any) => option.name}
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      const basketDetails = [...basketDetailsList];
                                      basketDetails[index].grade = newValue;
                                      setBasketDetailsList(basketDetails);
                                    }
                                  }}
                                  renderInput={(params) => <TextField {...params} label="เกรด" />}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2} alignItems={'center'} display={'flex'}>
                                <TextField
                                  fullWidth
                                  disabled={isDisable}
                                  type="number"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  label="จำนวนตะกร้า"
                                  helperText="ไม่เกิน 200 ตะกร้า"
                                  value={basket.basketAmount || ''}
                                  onChange={(e) => {
                                    const basketDetails = [...basketDetailsList];
                                    const basketWeight = basketDetails[index].emptyBasketWeight ?? 3;
                                    basketDetails[index].basketAmount = Number(e.target.value);
                                    basketDetails[index].totalBasketWeight = Number(e.target.value) * (basketWeight ?? 1);

                                    setBasketDetailsList(basketDetails);
                                    handleBasketAmount(index);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2} >
                                <TextField
                                  fullWidth
                                  disabled={isDisable}
                                  type="number"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  label="น้ำหนักตะกร้าเปล่า"
                                  value={basket.emptyBasketWeight || ''}
                                  onChange={(e) => {
                                    let inputValue = e.target.value;

                                    const basketDetails = [...basketDetailsList];
                                    const basketAmount = basketDetails[index]?.details?.filter((detail: IFarmReceiptBasket) => basketDetails[index]?.weightPerBasket && detail.weight && detail.weight > basketDetails[index]?.weightPerBasket).length ?? 0;
                                    basketDetails[index].emptyBasketWeight = Number(inputValue);
                                    basketDetails[index].totalBasketWeight = Number(inputValue) * basketAmount;

                                    basketDetails[index].details.forEach((detail) => {
                                      detail.emptyBasketWeight = Number(inputValue);
                                    });
                                    setBasketDetailsList(basketDetails);
                                    handleEmptyBasketWeight(index);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3.5} lg={3.5} alignItems={'center'} justifyContent={'center'}>
                                <Typography variant="body1">น้ำหนักทั้งหมด : {thaiFormatNumber((Number(basket.totalWeight)).toString())}</Typography>
                                <Typography variant="body1">น้ำหนักตะกร้าเปล่าทั้งหมด : {thaiFormatNumber(basket.totalBasketWeight.toString())}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2.5} lg={2.5} alignItems={'center'} justifyContent={'center'} >
                                <Typography variant="body1" fontWeight={'bold'}>น้ำหนักชั่งจริง : {thaiFormatNumber((Number(basket.netWeight)).toString())}</Typography>
                                <Typography variant="body1" fontWeight={'bold'}>น้ำหนักจากสวน : {thaiFormatNumber((Number(basket.netWeightFarm)).toString())}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2} alignItems={'center'} display={'flex'}>
                                <TextField
                                  fullWidth
                                  type="number"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  label="น้ำหนักจากสวนต่อตะกร้า"
                                  value={basket.weightPerBasket || ''}
                                  onChange={(e) => {
                                    const basketDetails = [...basketDetailsList];
                                    basketDetails[index].weightPerBasket = Number(e.target.value);
                                    setBasketDetailsList(basketDetails);
                                    handleCalWeightPerBasket(index);
                                    calNetWeightFarm();
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2} alignItems={'center'} display={'flex'}>
                                <TextField
                                  fullWidth
                                  type="number"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  label="ราคาต่อกิโล"
                                  value={basket.pricePerKg || ''}
                                  onChange={(e) => {
                                    const basketDetails = [...basketDetailsList];
                                    basketDetails[index].pricePerKg = Number(e.target.value);
                                    setBasketDetailsList(basketDetails);
                                    calPaidPrice(index);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4} alignItems={'center'} display={'flex'}>
                                <Typography variant="body1" fontWeight={'bold'}>ราคาตามน้ำหนัก : {basket.paidPrice ? thaiFormatNumber(basket.paidPrice.toString()) : 0}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4} alignItems={'center'} display={'flex'}>
                                <Typography variant="body1" fontWeight={'bold'}>ราคาจ่ายหน้าสวน : {basket.paidPriceActual ? thaiFormatNumber(basket.paidPriceActual.toString()) : 0}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={2} lg={2} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                            <Button
                              variant="contained"
                              startIcon={<ShoppingBasketIcon />}
                              color="info"
                              onClick={() => {
                                if (basket.basketAmount <= 200) {
                                  openHandleBasketDetails(index);
                                }
                              }}>
                              น้ำหนักตะกร้า
                            </Button>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Divider />
                          </Grid>

                        </Grid>
                      </Grid>
                    ))
                  }

                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Grid container spacing={2}>
                      {isMobile ? <>
                        <Grid item xs={4} sm={4} >
                          <Button
                            variant="outlined"
                            color="error"
                            sx={{ width: '100%' }}
                            disabled={isDisable}
                            type="button"
                            startIcon={<RemoveCircleIcon />}
                            onClick={() => {
                              removeFarmReceiptBasket();
                            }}
                          >
                            ลบ
                          </Button>
                        </Grid>


                        <Grid item xs={8} sm={8} >
                          <Button
                            variant="outlined"
                            sx={{ width: '100%' }}
                            disabled={isDisable}
                            type="button"
                            startIcon={<AddCircleIcon />}
                            onClick={() => {
                              addFarmReceiptBasket();
                            }}
                          >
                            เพิ่มรายการ
                          </Button>

                        </Grid></> : <Grid item md={12} lg={12}>
                        <Button
                          variant="outlined"
                          sx={{ width: '100%' }}
                          disabled={isDisable}
                          type="button"
                          startIcon={<AddCircleIcon />}
                          onClick={() => {
                            addFarmReceiptBasket();
                          }}
                        >
                          เพิ่มรายการ
                        </Button>

                      </Grid>
                      }

                    </Grid>
                  </Grid>
                  {
                    (userGroup?.canCost || userGroup?.canFinance) && (
                      <>
                        <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="h6">ราคา</Typography>
                        </Grid>
                        {
                          userGroup?.canCost && (
                            <>
                              <Grid item xs={12} sm={12} md={2} lg={2} textAlign={'center'}>
                                <Controller
                                  name={`pricePaidToFarm`}
                                  control={control}

                                  render={({ field }) => (
                                    <TextField
                                      fullWidth
                                      disabled={from === "order" || (props.permission.create || (props.farmProductReceipt.id && props.permission.edit)) ? false : isDisabled}
                                      type="number"
                                      InputProps={{ inputProps: { min: 0 } }}
                                      label="ราคารวมตามน้ำหนัก"
                                      {...field}
                                      error={!!errors.pricePaidToFarm}
                                      helperText={errors.pricePaidToFarm?.message || ''}
                                      InputLabelProps={{ shrink: (field.value || field.value === 0) ? true : undefined }}
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                        calTotalRemain();
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2} textAlign={'center'}>
                                <Controller
                                  name={`priceActualPaidToFarm`}
                                  control={control}
                                  rules={{ min: { value: 0, message: 'ราคาต้องไม่ติดลบ' } }}
                                  render={({ field }) => (
                                    <TextField
                                      fullWidth
                                      disabled={from === "order" || (props.permission.create || (props.farmProductReceipt.id && props.permission.edit)) ? false : isDisabled}
                                      type="number"
                                      InputProps={{ inputProps: { min: 0 } }}
                                      label="ราคารวมจ่ายหน้าสวน"
                                      {...field}
                                      error={!!errors.priceActualPaidToFarm}
                                      helperText={errors.priceActualPaidToFarm?.message || ''}
                                      InputLabelProps={{ shrink: (field.value || field.value === 0) ? true : undefined }}
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                        calTotalRemain();
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          )
                        }
                        {
                          userGroup?.canFinance && (
                            <>

                              <Grid item xs={6} sm={6} md={2} lg={2} textAlign={'center'}>
                                <Controller
                                  name={`deposit`}
                                  control={control}
                                  rules={{ min: { value: 0, message: 'เงินมัดจำต้องไม่ติดลบ' } }}
                                  render={({ field }) => (
                                    <TextField
                                      fullWidth
                                      type="number"
                                      InputProps={{ inputProps: { min: 0 } }}
                                      label="เงินมัดจำ"
                                      {...field}
                                      error={!!errors.deposit}
                                      helperText={errors.deposit?.message || ''}
                                      InputLabelProps={{ shrink: (field.value || field.value === 0) ? true : undefined }}
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                        calGrandTotal();
                                        calTotalRemain();
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6} sm={6} md={2} lg={2} textAlign={'center'}>
                                <FormControl fullWidth>
                                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                                    <Controller
                                      name="depositDate"
                                      control={control}
                                      render={({ field }) => (
                                        <DatePicker
                                          slotProps={{
                                            textField: {
                                              variant: 'outlined',
                                              error: !!errors.depositDate,
                                              helperText: errors.depositDate ? "กรุณาระบุวันที่มัดจำ" : "",
                                            }
                                          }}
                                          label="วันที่มัดจำ"
                                          format="DD/MM/YYYY"
                                          {...field}
                                          defaultValue={dayjs(new Date())}
                                          value={field.value ? dayjs(field.value) : dayjs(new Date())}
                                          onChange={(date) => field.onChange(date)}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2} lg={2} textAlign={'center'}>
                                <Controller
                                  name={`totalRemain`}
                                  control={control}
                                  rules={{ min: { value: 0, message: 'ยอดคงเหลือต้องไม่ติดลบ' } }}
                                  render={({ field }) => (
                                    <TextField
                                      fullWidth
                                      type="number"
                                      InputProps={{ inputProps: { min: 0 } }}
                                      label="ยอดคงเหลือ"
                                      {...field}
                                      error={!!errors.totalRemain}
                                      helperText={errors.totalRemain?.message || ''}
                                      InputLabelProps={{ shrink: (field.value || field.value === 0) ? true : undefined }}
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                        calGrandTotal();
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6} sm={6} md={2} lg={2} textAlign={'center'}>
                                <FormControl fullWidth>
                                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                                    <Controller
                                      name="remainPaymentDate"
                                      control={control}
                                      render={({ field }) => (
                                        <DatePicker
                                          slotProps={{
                                            textField: {
                                              variant: 'outlined',
                                              error: !!errors.remainPaymentDate,
                                              helperText: errors.remainPaymentDate ? "กรุณาระบุวันที่จ่าย" : "",
                                            }
                                          }}
                                          label="วันที่จ่าย"
                                          format="DD/MM/YYYY"
                                          {...field}
                                          defaultValue={dayjs(new Date())}
                                          value={field.value ? dayjs(field.value) : dayjs(new Date())}
                                          onChange={(date) => field.onChange(date)}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </FormControl>
                              </Grid>
                            </>
                          )
                        }
                      </>
                    )
                  }
                  {
                    userGroup?.canFinance && (
                      <>
                        <Grid item xs={12} sm={12} mt={2} mb={2} md={12} lg={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="h6">ค่าใช้จ่ายอื่นๆ</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>

                          {watchData?.map((data: IFarmExpenseDetail, index: any) => (
                            <Grid container spacing={2} key={data.id} mb={3}>
                              {
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} ml={1}>
                                      <Controller
                                        name={`farmExpenseDetail.${index}.type`}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                          <RadioGroup
                                            {...field}
                                            row
                                            onChange={(e) => {
                                              field.onChange(parseFloat(e.target.value));
                                            }}
                                          >
                                            <FormControlLabel
                                              value={1}
                                              control={<Radio />}
                                              label="ค่ารถ"
                                              disabled={isDisabled}
                                            />
                                            <FormControlLabel
                                              value={2}
                                              control={<Radio />}
                                              label="ค่าใช้จ่ายอื่นๆ"
                                              disabled={isDisabled}
                                            />

                                          </RadioGroup>
                                        )}
                                      />
                                    </Grid>
                                    {!isMobile && (
                                      <Grid item xs={1} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                        <IconButton
                                          disabled={isDisable}
                                          color="error"
                                          onClick={() => {
                                            remove(index);
                                          }}
                                        >
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      </Grid>
                                    )}
                                    <Grid item xs={11} sm={11.5} md={11.5} lg={11.5}>
                                      <Grid container spacing={2}>
                                        {
                                          data.type === 1 && (
                                            <>
                                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                                <Controller
                                                  name={`farmExpenseDetail.${index}.car`}
                                                  control={control}
                                                  rules={{ required: true }}
                                                  render={({ field: { onChange, value } }) => (
                                                    <Autocomplete
                                                      disabled={isDisabled}
                                                      value={value as ICar || null}
                                                      options={props.cars ?? [] as ICar[]}
                                                      getOptionLabel={(option: ICar) => {
                                                        if (typeof option === 'string') {
                                                          return option;
                                                        }
                                                        return option && option.plateNumber ? `${option.plateNumber} ${option.province?.nameTh}` : "";
                                                      }}
                                                      isOptionEqualToValue={(option, value) => option.id === value.id}
                                                      onChange={(_, newValue) => {
                                                        if (newValue?.id === "create") {
                                                          handleOpenCreateDailog("car");
                                                          onChange(null);
                                                        } else if (newValue?.id) { onChange(newValue); }
                                                      }}
                                                      filterOptions={(options, params) => {
                                                        const filtered = filterOptions(options, params);
                                                        if (params.inputValue !== '') {
                                                          filtered.push({
                                                            plateNumber: `สร้าง '${params.inputValue}'`,
                                                            id: 'create',
                                                          });
                                                          setName(params.inputValue);
                                                        }
                                                        return filtered;
                                                      }}
                                                      renderOption={(props, option) => {
                                                        const { key, ...rest } = props;
                                                        return (
                                                          <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                                            <AddCircleIcon /><Box component={'span'} ml={1}> {option.plateNumber}</Box>
                                                          </Box> : option.plateNumber}</Typography>
                                                        );
                                                      }}
                                                      clearOnBlur
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          label="ทะเบียนรถ"
                                                          error={!!errors.farmExpenseDetail?.[index]?.car}
                                                          helperText={errors.farmExpenseDetail?.[index]?.car ? "กรุณาเลือกทะเบียนรถ" : ""}
                                                        />
                                                      )}
                                                    />
                                                  )}
                                                />
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                                <Controller
                                                  name={`farmExpenseDetail.${index}.driver`}
                                                  control={control}
                                                  rules={{ required: true }}
                                                  render={({ field: { onChange, value } }) => (
                                                    <Autocomplete
                                                      disabled={isDisabled}
                                                      value={value as IDriver || null}
                                                      options={props.drivers ?? [] as IDriver[]}
                                                      getOptionLabel={(option: IDriver) => {
                                                        if (typeof option === 'string') {
                                                          return option;
                                                        }
                                                        return option && option.firstName ? `${option.firstName} ${option.lastName}` : "";
                                                      }}
                                                      isOptionEqualToValue={(option, value) => option.id === value.id}
                                                      onChange={(_, newValue) => {
                                                        if (newValue?.id === "create") {
                                                          handleOpenCreateDailog("driver");
                                                          onChange(null);
                                                        } else if (newValue?.id) { onChange(newValue); }
                                                      }}
                                                      filterOptions={(options, params) => {
                                                        const filtered = filterOptions(options, params);
                                                        if (params.inputValue !== '') {
                                                          filtered.push({
                                                            firstName: `สร้าง '${params.inputValue}'`,
                                                            id: 'create',
                                                          });
                                                          setName(params.inputValue);
                                                        }
                                                        return filtered;
                                                      }}
                                                      renderOption={(props, option) => {
                                                        const { key, ...rest } = props;
                                                        return (
                                                          <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                                            <AddCircleIcon /><Box component={'span'} ml={1}> {option.firstName} </Box>
                                                          </Box> : option.firstName + " " + option.lastName}</Typography>
                                                        );
                                                      }}
                                                      clearOnBlur
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          label="คนขับรถ"
                                                          error={!!errors.farmExpenseDetail?.[index]?.driver}
                                                          helperText={errors.farmExpenseDetail?.[index]?.driver ? "กรุณาเลือกคนขับรถ" : ""}
                                                        />
                                                      )}
                                                    />
                                                  )}
                                                />
                                              </Grid>
                                            </>
                                          )
                                        }
                                        {
                                          data.type === 2 && (
                                            <>
                                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                                <Controller
                                                  name={`farmExpenseDetail.${index}.expenseType`}
                                                  control={control}
                                                  rules={{ required: true }}
                                                  render={({ field }) => (
                                                    <Autocomplete
                                                      {...field}
                                                      disabled={isDisabled}
                                                      value={field.value as IExpenseType || null}
                                                      options={props.expenseTypes ?? [] as IExpenseType[]}
                                                      getOptionLabel={(option: IExpenseType) => {
                                                        if (typeof option === 'string') {
                                                          return option;
                                                        }
                                                        return option && option.name ? option.name : "";
                                                      }}
                                                      isOptionEqualToValue={(option, value) => option.id === value.id}
                                                      onChange={(event, newValue) => {
                                                        if (newValue?.id === "create") {
                                                          setOpenExpenseType(true);
                                                        } else if (newValue?.id !== "create") {
                                                          field.onChange(newValue);
                                                        }
                                                      }}
                                                      filterOptions={(options, params) => {
                                                        const filtered = filterOptions(options, params);
                                                        if (params.inputValue !== '') {
                                                          filtered.push({
                                                            name: `สร้าง '${params.inputValue}'`,
                                                            id: 'create',
                                                          });
                                                        }
                                                        return filtered;
                                                      }}
                                                      renderOption={(props, option) => {
                                                        const { key, ...rest } = props;
                                                        return (
                                                          <Typography key={key} {...rest} variant="body2" >{option.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                                            <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                                                          </Box> : option.name}</Typography>
                                                        );
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          label="ค่าใช้จ่าย"
                                                          error={!!errors.farmExpenseDetail?.[index]?.expenseType}
                                                          helperText={errors.farmExpenseDetail?.[index]?.expenseType ? "กรุณาเลือกค่าใช้จ่าย" : ""}
                                                        />
                                                      )}
                                                    />
                                                  )}
                                                />
                                              </Grid>
                                            </>
                                          )
                                        }
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                          <Controller
                                            name={`farmExpenseDetail.${index}.amount`}
                                            control={control}
                                            rules={{ min: { value: 0, message: 'ราคาต้องไม่ติดลบ' } }}
                                            render={({ field }) => (
                                              <TextField
                                                disabled={isDisabled}
                                                fullWidth
                                                type="number"
                                                InputProps={{ inputProps: { min: 0 } }}
                                                label="ราคา"
                                                {...field}
                                                error={!!errors.farmExpenseDetail?.[index]?.amount}
                                                helperText={errors.farmExpenseDetail?.[index]?.amount?.message || ''}
                                                InputLabelProps={{ shrink: field.value ? true : undefined }}
                                                onChange={(e) => {
                                                  field.onChange(e.target.value);
                                                }}
                                              />
                                            )}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                          <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                                              <Controller
                                                name={`farmExpenseDetail.${index}.payDate`}
                                                control={control}
                                                render={({ field }) => (
                                                  <DatePicker
                                                    disabled={isDisabled}
                                                    slotProps={{
                                                      textField: {
                                                        variant: 'outlined',
                                                        error: !!errors.farmExpenseDetail?.[index]?.payDate,
                                                        helperText: errors.farmExpenseDetail?.[index]?.payDate ? "กรุณาระบุวันที่จ่าย" : "",
                                                      }
                                                    }}
                                                    label="วันที่จ่าย"
                                                    format="DD/MM/YYYY"
                                                    {...field}
                                                    defaultValue={dayjs(new Date())}
                                                    value={field.value ? dayjs(field.value) : dayjs(new Date())}
                                                    onChange={(date) => field.onChange(date)}
                                                  />
                                                )}
                                              />
                                            </LocalizationProvider>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                          <Controller
                                            name={`farmExpenseDetail.${index}.payBy`}
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                              <Autocomplete
                                                {...field}
                                                disabled={isDisabled}
                                                value={field.value as IUserSuperAdmin || null}
                                                options={props.users ?? [] as IUserSuperAdmin[]}
                                                getOptionLabel={(option: IUserSuperAdmin) => {
                                                  if (typeof option === 'string') {
                                                    return option;
                                                  }
                                                  return option && option.firstName ? option.firstName : "";
                                                }}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                onChange={(event, newValue) => {
                                                  field.onChange(newValue);
                                                }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="ผู้จ่าย"
                                                    error={!!errors.farmExpenseDetail?.[index]?.payBy}
                                                    helperText={errors.farmExpenseDetail?.[index]?.payBy ? "กรุณาเลือกผู้จ่าย" : ""}
                                                  />
                                                )}
                                              />
                                            )}
                                          />
                                        </Grid>
                                      </Grid>

                                    </Grid>
                                  </Grid>
                                </Grid>
                              }
                            </Grid>
                          ))}
                          <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                            <Grid container spacing={2}>
                              {isMobile ? <>
                                <Grid item xs={4} sm={4} >
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{ width: '100%' }}
                                    disabled={isDisable}
                                    type="button"
                                    startIcon={<RemoveCircleIcon />}
                                    onClick={() => {
                                      remove(fields.length - 1);
                                    }}

                                  >
                                    ลบ
                                  </Button>
                                </Grid>
                                <Grid item xs={8} sm={8} >
                                  <Button
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    disabled={isDisable}
                                    type="button"
                                    startIcon={<AddCircleIcon />}
                                    onClick={() => append({
                                      id: uuidv4(),
                                      type: 1,
                                      amount: 0,
                                      payDate: new Date(),
                                      payBy: props.user
                                    })}
                                  >
                                    เพิ่มรายการ
                                  </Button>

                                </Grid></> : <Grid item md={12} lg={12}>
                                <Button
                                  variant="outlined"
                                  sx={{ width: '100%' }}
                                  disabled={isDisable}
                                  type="button"
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => append({
                                    id: uuidv4(),
                                    type: 1,
                                    amount: 0,
                                    payDate: new Date(),
                                    payBy: props.user
                                  })}
                                >
                                  เพิ่มรายการ
                                </Button>

                              </Grid>
                              }

                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )
                  }

                  <Grid item xs={12} sm={12} mt={2} mb={2} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={7} md={7} lg={7}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography>เอกสาร</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button disabled={isDisabled} component="label" variant="outlined" startIcon={<UploadFileIcon />}>
                          เลือกไฟล์
                          <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png, .pdf" multiple onChange={handleFileChange} />
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png, .pdf</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {selectedFiles.length > 0 && (
                          <Box component={'div'} mt={1}>
                            {selectedFiles.map((file: any, index: number) => (
                              <Grid item xs={12} sm={12} md={12} key={`sf-${index.toString()}`} mt={1}>
                                <Grid container >
                                  <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton size="small" color="error" onClick={() => handleFileRemove(index)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11} sm={11} md={11} lg={11}>
                                    {file.type === 'application/pdf' ? (
                                      <Typography variant="body2">
                                        <a
                                          href={URL.createObjectURL(file)}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ color: "inherit", cursor: "pointer" }}
                                        >
                                          {file.name}
                                        </a>
                                      </Typography>
                                    ) : (
                                      <img
                                        src={URL.createObjectURL(file)}
                                        alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                        style={{ maxWidth: "50%", height: "auto", cursor: "pointer" }}
                                        onClick={() => onImageClick(URL.createObjectURL(file))}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {images && (
                          <Box component={'div'} mt={1}>
                            {images.map((file: any, index: number) => (
                              <Grid item xs={12} sm={12} md={12} key={`im-${index.toString()}`} mt={1}>
                                <Grid container >
                                  <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton size="small"
                                      color="error" onClick={() => handleFileRemoveImageList(file)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11} sm={11} md={11} lg={11}>
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}/uploads/${(file).name
                                        }`}
                                      alt="Selected"
                                      style={{ maxWidth: "50%", height: "auto", cursor: 'pointer' }}
                                      onClick={() => onImageClick(`${process.env.REACT_APP_API_URL}/uploads/${(file).name}`)}

                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        {pdfFiles.length > 0 && (
                          <Box component={'div'} mt={1}>
                            {pdfFiles.map((file: any, index: number) => (
                              <Grid item xs={12} key={`pdf-${index}`} mt={1}>
                                <Grid container>
                                  <Grid item xs={1} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                    <IconButton size="small" color="error" onClick={() => handleFileRemovePdfList(file)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11}>
                                    <a
                                      href={`${process.env.REACT_APP_API_URL}/uploads/${file.name}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: "inherit", cursor: "pointer" }}
                                    >
                                      {file.originalName}
                                    </a>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} mt={2} mb={2} md={12} lg={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={4}>
                            <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} alignItems={"center"}>
                              <Typography>
                                <b>ผู้ทํารายการ: </b>
                                {props.farmProductReceipt?.createBy?.firstName} {props.farmProductReceipt?.createBy?.lastName}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} alignItems={"center"}>
                              <Typography>
                                <b>ผู้อนุมัติ/ปฏิเสธ: </b>
                                {props.farmProductReceipt?.approveBy?.firstName} {props.farmProductReceipt?.approveBy?.lastName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={5} md={5} lg={5} justifyContent={'flex-end'}>
                    <Grid container spacing={2}>

                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography >น้ำหนักรวมตะกร้าทั้งหมด (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        {thaiFormatNumber(watch('totalWeight')?.toString() || '0')}
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography >น้ำหนักตะกร้าเปล่าทั้งหมด (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        {thaiFormatNumber(totalBasketWeight.toString() || '0')}
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography >น้ำหนักชั่งจริงทั้งหมด (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        {thaiFormatNumber(watch('netWeightFarm')?.toString() || '0')}
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography >น้ำหนักจากสวนทั้งหมด (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        {thaiFormatNumber(totalWeightFromFarm.toString() || '0')}
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography fontWeight={'bold'}>น้ำหนักส่วนต่าง (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography fontWeight={'bold'}>{thaiFormatNumber(watch('totalDiffWeight')?.toString() || '0')}</Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography fontWeight={'bold'}>มูลค่าส่วนต่าง (บาท)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography fontWeight={'bold'}>{thaiFormatNumber(watch('totalDiffPrice')?.toString() || '0')}</Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography >จำนวนตะกร้าทั้งหมด</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        {thaiFormatNumber(watch('basketAmount')?.toString() || '0')}
                      </Grid>

                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography >น้ำหนักสุ่มผ่าก่อนชั่ง (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Controller
                          name={`preWeighingWeight`}
                          control={control}
                          rules={{ min: { value: 0, message: 'น้ำหนักต้องไม่ติดลบ' } }}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              type="number"
                              InputProps={{ inputProps: { min: 0 } }}
                              {...field}
                              error={!!errors.preWeighingWeight}
                              helperText={errors.preWeighingWeight?.message || ''}
                              InputLabelProps={{ shrink: field.value ? true : undefined }}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                calNetWeightFarm();
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography fontWeight={'bold'}>น้ำหนักสุทธิ (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography fontWeight={'bold'}>
                          {thaiFormatNumber(watch('netWeight')?.toString() || '0')}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography fontWeight={'bold'}>ราคาสุทธิ (บาท)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography fontWeight={'bold'}>
                          {thaiFormatNumber(watch('grandTotal')?.toString() || '0')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} justifyContent={"end"}>
                    <Stack spacing={2} direction="row">
                      {
                        isFromStatus ? (
                          <>
                            {
                              userGroup?.canApprove && props.farmProductReceipt.status === "waiting" ? (
                                <>
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    color="error"
                                    onClick={onReject}
                                  >
                                    ปฏิเสธ
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="success"
                                    fullWidth
                                    onClick={onApprove}
                                    style={{ marginLeft: '8px' }}
                                  >
                                    อนุมัติ
                                  </Button>
                                </>
                              ) : (
                                <>
                                  {
                                    <> <Button
                                      variant="outlined"
                                      fullWidth
                                      onClick={() => navigate("/process-product-receipt")}
                                    >
                                      ยกเลิก
                                    </Button>
                                      {
                                        (props.permission.create || (props.farmProductReceipt.id && props.permission.edit)) ?
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleSave}
                                          >
                                            บันทึก
                                          </Button>
                                          : <></>
                                      }
                                    </>
                                  }
                                </>
                              )
                            }
                          </>
                        ) :
                          (<>
                            {
                              <> <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => navigate("/process-product-receipt")}
                              >
                                ยกเลิก
                              </Button>
                                {
                                  (props.permission.create || (props.farmProductReceipt.id && props.permission.edit)) && props.farmProductReceipt.status !== 'approved' ?
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      fullWidth
                                      onClick={handleSave}
                                    >
                                      บันทึก
                                    </Button>
                                    : <></>
                                }
                              </>
                            }
                          </>)
                      }

                    </Stack>
                  </Grid>

                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            onDelete();
          } else {
            onSubmit();
          }
        }}
      />
      <ImagePreview
        open={openImage}
        imageSrc={selectedImage}
        onClose={() => setOpenImage(false)}
      />
      <DialogCreate datas={openCreateDailog?.datas} open={openCreateDailog?.open && (openCreateDailog?.type === "agent" || openCreateDailog?.type === "vapourHeatTreatment")} title={openCreateDailog?.title} type={openCreateDailog?.type} onClose={() => setOpenCreateDailog({ ...openCreateDailog, open: false })} onSubmit={(data, type) => { props?.onCreateDialog(data, type); }} onFetchData={(id, type) => {
        props?.onFetchData(id, type);
      }} onCreateDialog={(data, type) => { props?.onCreateDialog(data, type); }} />
      <CarCreateView
        open={openCreateDailog?.open && openCreateDailog?.type === "car"}
        provinceData={props.provinceData || []}
        onClose={() => setOpenCreateDailog({ ...openCreateDailog, open: false })}
        onSubmit={(data) => { props?.onCreateDialog(data, nameType); }}
        permission={props.permission} name={name} />
      <DriverCreateView
        open={openCreateDailog?.open && openCreateDailog?.type === "driver"}
        onClose={() => setOpenCreateDailog({ ...openCreateDailog, open: false })}
        onSubmit={(data) => { props?.onCreateDialog(data, nameType); }}
        permission={props.permission} name={name} />
      <ExpenseTypeCreateView
        open={openExpenseType}
        onClose={() => setOpenExpenseType(false)}
        data={{} as IExpenseType}
        isLoading={false}
        onSubmit={(data: IExpenseType) => {
          setOpenExpenseType(false);
          props.onCreateExpenseType(data);
        }}
        permission={props.permission}
        name={name}
      />
      <DialogCreateBasketDetails
        open={openBasketDetails}
        onClose={() => setOpenBasketDetails(false)}
        onSubmit={handleBasketDetails}
        datas={basketDetails ?? [] as IFarmReceiptBasket[]}
        index={basketIndex}
        weightPerBasket={basketDetailsList[basketIndex]?.weightPerBasket || 0}
      />
      <SimpleBackdrop open={props.isLoading ?? false} />
    </Box >
  );
};

export default FarmProductReceiptCreateView;;;