import { FC } from "react";
import { IReportCriteria, ISalesReport, ISalesReports } from "../Services/ReportService";
import ReportListView from "../../../components/ReportListView";
import dayjs from "dayjs";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";

interface SalesReportListViewProps {
  isLoading: boolean;
  salesReportData: ISalesReports;
  getSalesReportData: (criteria: IReportCriteria) => void;

}
const SalesReportListView: FC<SalesReportListViewProps> = (props) => {
  const navigate = useNavigate();
  const salesReportColumns = [
    { label: 'วันที่', render: (row: ISalesReport) => row.orderDate ? dayjs(row.orderDate).format("DD/MM/YYYY HH:mm") : "" },
    { label: 'Invoice No.', render: (row: ISalesReport) => row.invoiceNo ?? "" },
    { label: 'ชื่อโปรเจกต์', render: (row: ISalesReport) => row.projectName ?? "" },
    {
      label: 'มูลค่าทั้งหมด', render: (row: ISalesReport) => (
        <strong>{row.totalSales ? thaiFormatNumber(row.totalSales.toString()) : "0.00"}</strong>
      )
    },

  ];

  const totalValues = [
    { label: "ต้นทุน", value: props.salesReportData?.totalValue?.totalCost, percentage: props.salesReportData?.totalValue?.costPercentChange },
    { label: "ยอดขาย", value: props.salesReportData?.totalValue?.totalSales, percentage: props.salesReportData?.totalValue?.salesPercentChange },
    { label: "ยอดสูญเสีย", value: props.salesReportData?.totalValue?.totalLoss, percentage: props.salesReportData?.totalValue?.lossPercentChange },
    { label: "รายได้อื่นๆ", value: props.salesReportData?.totalValue?.otherIncome},
    { label: "กำไร", value: props.salesReportData?.totalValue?.totalProfit, percentage: props.salesReportData?.totalValue?.profitPercentChange },
  ];

  const rowActions = (row: ISalesReport) => (
    <IconButton
      onClick={() => {
        navigate(`/process-order-edit/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  return (
    <>
      <ReportListView
        isLoading={props.isLoading}
        reportData={props.salesReportData}
        getReportData={props.getSalesReportData}
        reportTitle="รายงานยอดขาย"
        totalValues={totalValues}
        reportColumns={salesReportColumns}
        rowActions={rowActions}
      />
    </>

  );
};

export default SalesReportListView;
